import React from 'react';
import './Storefront.css';
import { EditableCell } from './EditableCell';

export const StatusSelector = ({ row, status, column, table }) => {
  return (
    <>
      {row.getIsSelected() ? (
        <EditableCell
          inputType={'select'}
          // getValue={'getValue'}
          getValue={() => {
            return status;
          }}
          row={row}
          column={column}
          table={table}
        />
      ) : (
        <span className={`status-cell-${status}`}>{status}</span>
      )}
    </>
  );
};
