import React, { useCallback, useEffect, useState } from 'react';
import './AccountSettings.css';
import API from '../API';
import { useAuth0 } from '@auth0/auth0-react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import LogoutIcon from './images/icon-log-out.png';
import SettingsIcon from './images/icon-settings.png';
import WalletIcon from './images/icon-wallet.png';
import ConnectWalletButton from './ConnectWalletButton';
import NFTBindingIcon from './images/nft_binding_icon.png';
import NamiIcon from './images/nami_icon.png';
import EternlIcon from './images/eternl_icon.png';
import MetamaskIcon from './images/metamask_icon.png';
import NiftyGatewayIcon from './images/nifty_gateway_icon.png';
import YoroiIcon from './images/yoroi_icon.png';
import GeroWalletIcon from './images/gerowallet_icon.png';
import LaceIcon from './images/lace_icon.png';
import FlintIcon from './images/flint_icon.png';
import TransactionHistoryIcon from './images/transaction_history_icon.png';
import Mixpanel from './Mixpanel';

const AccountSettings = ({ wallets, setWallets }) => {
  const transactionHistoryEnabled = false;
  const nftBindingEnabled = false;
  const { isAuthenticated, getAccessTokenSilently, user, logout, isLoading } = useAuth0();
  const [accessToken, setAccessToken] = useState(null);
  const [username, setUsername] = useState('');
  const [items, setItemsData] = useState([]);
  const [purchases, setPurchases] = useState([]);

  const reloadWallets = useCallback(async () => {
    if (isAuthenticated) {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: 'ffclient:play'
      });

      const response = await API.getWallets(accessToken);
      if (response.status !== 200) {
        console.log('Error getting wallets.');
        return;
      }

      const data = await response.json();
      setWallets(data.wallets);
    }
  }, [getAccessTokenSilently, isAuthenticated, setWallets]);

  const truncateWalletAddress = function (fullStr, strLen, separator) {
    if (fullStr.length <= strLen) return fullStr;

    separator = separator || '...';

    var sepLen = separator.length,
      charsToShow = strLen - sepLen,
      frontChars = Math.ceil(charsToShow / 2),
      backChars = Math.floor(charsToShow / 2);

    return fullStr.substr(0, frontChars) + separator + fullStr.substr(fullStr.length - backChars);
  };

  useEffect(() => {
    Mixpanel.track('Page Viewed', {
      'Page Type': 'Account Settings'
    });
  }, []);

  useEffect(() => {
    async function getWallets() {
      if (isAuthenticated) {
        const accessToken = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: 'ffclient:play'
        });
        setAccessToken(accessToken);

        setUsername(user['https://futurefest.io/username']);
        await reloadWallets();
      }
    }
    async function getItems() {
      if (isAuthenticated) {
        const accessToken = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: 'ffclient:play'
        });

        const response = await API.getDigitalItems(accessToken);
        if (response.status !== 200) {
          console.log('Error getting digital items.');
          return;
        }

        const data = await response.json();
        setItemsData(data.items);
      }
    }

    async function getPurchases() {
      if (isAuthenticated) {
        const accessToken = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: 'ffclient:play'
        });

        const response = await API.getPurchasedItems(accessToken);
        if (response.status !== 200) {
          console.log('Error getting digital items.');
          return;
        }

        const data = await response.json();
        setPurchases(data.items);
      }
    }
    getWallets();
    getItems();
    getPurchases();
  }, [getAccessTokenSilently, isAuthenticated, user, reloadWallets]);

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return (
    <Tabs
      disableUpDownKeys={true}
      className="account-settings"
      selectedTabClassName="account-settings__tab--selected"
      selectedTabPanelClassName="account-settings__tab-panel--selected"
    >
      <TabList className="account-settings__tab-list">
        <Tab className="account-settings__tab">
          <img src={SettingsIcon} alt="Settings icon" />
          <span>Account Settings</span>
        </Tab>
        <Tab className="account-settings__tab">
          <img src={WalletIcon} alt="Wallet icon" />
          <span>Connect Wallet</span>
        </Tab>
        {transactionHistoryEnabled && (
          <Tab className="account-settings__tab">
            <img src={TransactionHistoryIcon} alt="Transaction History icon" />
            <span>Transaction History</span>
          </Tab>
        )}
        {nftBindingEnabled && (
          <Tab className="account-settings__tab">
            <img src={NFTBindingIcon} alt="NFT Binding icon" />
            <span>NFT Binding</span>
          </Tab>
        )}
        <button
          className="ff-button-logout bottom-center"
          onClick={() => logout({ returnTo: window.location.origin })}
        >
          <img src={LogoutIcon} alt="Logout icon" />
          &nbsp;&nbsp;Logout
        </button>
      </TabList>

      <TabPanel className="account-settings__tab-panel">
        <h2 className="ff-header">Account Settings</h2>
        <br />
        <label htmlFor="input-username">Username:</label>&nbsp;
        <input
          id="input-username"
          className="username-input"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          type="text"
          disabled={true}
        ></input>
        <br />
        <br />
        <button
          className="ff-button"
          style={{ display: 'none' }}
          onClick={() => API.updateUsername(accessToken, { username: username })}
          disabled={true}
        >
          Update
        </button>
      </TabPanel>
      <TabPanel className="account-settings__tab-panel">
        <h2 className="ff-header">Connect Wallet</h2>
        {wallets.length === 0 && <div>No wallets connected.</div>}
        {wallets
          .filter((wallet) => wallet.wallet !== 'nmkr') // Exclude 'nmkr' managed wallets
          .map((wallet) => (
            <div key={wallet.address} className="wallet-address-line">
              <span className="wallet-address">
                {wallet.wallet === 'nami' && (
                  <img className="wallet-icon" src={NamiIcon} alt="Nami wallet icon" />
                )}
                {wallet.wallet === 'eternl' && (
                  <img className="wallet-icon" src={EternlIcon} alt="Eternl wallet icon" />
                )}
                {wallet.wallet === 'metamask' && (
                  <img className="wallet-icon" src={MetamaskIcon} alt="Metamask wallet icon" />
                )}
                {wallet.wallet === 'nifty' && (
                  <img
                    className="wallet-icon"
                    src={NiftyGatewayIcon}
                    alt="Nifty gateway wallet icon"
                  />
                )}
                {wallet.wallet === 'yoroi' && (
                  <img className="wallet-icon" src={YoroiIcon} alt="Yoroi wallet icon" />
                )}
                {wallet.wallet === 'gerowallet' && (
                  <img className="wallet-icon" src={GeroWalletIcon} alt="Gero wallet icon" />
                )}
                {wallet.wallet === 'lace' && (
                  <img className="wallet-icon" src={LaceIcon} alt="Lace wallet icon" />
                )}
                {wallet.wallet === 'flint' && (
                  <img className="wallet-icon" src={FlintIcon} alt="Flint wallet icon" />
                )}
                {truncateWalletAddress(wallet.address, 80)}
              </span>
              <button
                className="wallet-delete-button"
                onClick={async () => {
                  const response = await API.deleteWallet(accessToken, wallet.id);
                  if (response.status !== 200) {
                    console.log('Error getting wallets.');
                    return;
                  }

                  const data = await response.json();
                  setWallets(data.wallets);
                }}
              >
                X
              </button>
            </div>
          ))}
        <br />
        <ConnectWalletButton wallets={wallets} setWallets={setWallets} />
      </TabPanel>
      {transactionHistoryEnabled && (
        <TabPanel className="account-settings__tab-panel transaction_tab">
          <h2 className="ff-header">Transaction History</h2>
          <div className="transaction_table">
            <table>
              <thead>
                <tr>
                  <th colSpan={2} className="transaction_table_th">
                    item name
                  </th>
                  <th className="transaction_table_th">price</th>
                  <th className="transaction_table_th">purchase date</th>
                  <th className="transaction_table_th">status</th>
                </tr>
              </thead>
              <tbody>
                {purchases.map((purchases) => (
                  <tr>
                    <td className="transaction_table_td">{purchases.image_url}</td>
                    <td className="transaction_table_td">{purchases.name}</td>
                    <td className="transaction_table_td">{purchases.price}</td>
                    <td className="transaction_table_td">{purchases.purchase_date}</td>
                    <td
                      className={`transaction_table_td ${
                        purchases.status === 'in' ? 'status_text_green' : 'status_text_red'
                      }`}
                    >
                      {purchases.status}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <br />
          {purchases === 0 && <div>You do not have any transactions</div>}
          <br />
        </TabPanel>
      )}
      {nftBindingEnabled && (
        <TabPanel className="account-settings__tab-panel transaction_tab">
          <h2 className="ff-header">NFT Binding</h2>
          <p className="nft-binding-details">
            NFT Binding is a new way for you to have ownership of your digital assets. Binding your
            digital purchase to an NFT will allow you to access any items purchased when reading a
            wallet containing that NFT.
          </p>
          <br />
          <h6 className="select-nft-to-bind-txt">Your Digital Items</h6>
          <div className="nft-binding-doubts">
            <p className="nft-binding-details">
              These are digital items that you own that are not currently bound to an NFT. Select an
              NFT you've added above from the dropdown to bind.
            </p>
          </div>
          <br />
          <div className="transaction_table">
            <table>
              <thead>
                <tr>
                  <th className="transaction_table_th"></th>
                  <th className="transaction_table_th"></th>
                  <th className="transaction_table_th">item name</th>
                  <th className="transaction_table_th">type</th>
                  <th className="transaction_table_th">bound to</th>
                  <th className="transaction_table_th"></th>
                </tr>
              </thead>
              <tbody>
                {items.map((item) => (
                  <tr>
                    <td className="transaction_table_td">{item.image_url}</td>
                    <td className="transaction_table_td">{item.shop_item_instance_id}</td>
                    <td className="transaction_table_td">{item.name}</td>
                    <td className="transaction_table_td">{item.type}</td>
                    <td className="transaction_table_td">{item.bound_to.name}</td>
                    <td
                      className={`transaction_table_td ${
                        item.bound_to === null ? 'unbound_text_red' : 'bound_text_blue'
                      }`}
                    >
                      {item.bound_to.nft_id}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {items === 0 && <div>You do not have any items</div>}
          </div>
        </TabPanel>
      )}
    </Tabs>
  );
};

export default AccountSettings;
