import React, { useState, useEffect } from 'react';
import { Tabs, TabPanel } from 'react-tabs';
import NamiIcon from './images/nami_icon.png';
import EternlIcon from './images/eternl_icon.png';
import YoroiIcon from './images/yoroi_icon.png';
import GeroWalletIcon from './images/gerowallet_icon.png';
import LaceIcon from './images/lace_icon.png';
import FlintIcon from './images/flint_icon.png';
import './Cart.css';
import { CartState } from './CartContext';
import { useAuth0 } from '@auth0/auth0-react';
import API from '../API';
import { Decoder, Encoder } from 'cbor-x';
import { Buffer } from 'buffer';
import { toast } from 'react-toastify';
import Mixpanel from './Mixpanel';

const Cart = ({ showSuccess }) => {
  const eternlWalletEnabled = false;
  const yoroiWalletEnabled = false;
  const geroWalletEnabled = false;
  const laceWalletEnabled = false;
  const flintWalletEnabled = false;
  const { isAuthenticated, loginWithPopup, getAccessTokenSilently } = useAuth0();
  const { cartItems, setCartItems } = CartState();
  const [wallets, setWallets] = useState(null);
  const [isPurchasedSuccessfully, setIsPurchasedSuccessfully] = useState(showSuccess);
  const [selectedWallet, setSelectedWallet] = useState(null);
  const [isWalletConnected, setIsWalletConnected] = useState(false);

  useEffect(() => {
    Mixpanel.track('Page Viewed', {
      'Page Type': 'Checkout Cart'
    });
  }, []);

  useEffect(() => {
    async function getWallets() {
      if (isAuthenticated) {
        const accessToken = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: 'ffclient:play'
        });

        const response = await API.getWallets(accessToken);
        if (response.status !== 200) {
          console.log('Error getting wallets.');
          return;
        }
        const data = await response.json();
        setWallets(data.wallets);
      }
    }
    getWallets();
  }, [getAccessTokenSilently, isAuthenticated, isWalletConnected]);

  const removeCartItem = (brand_id, item_id) => {
    let tempShopItems = Object.assign([], cartItems);

    Mixpanel.track('Item Removed from Cart', {
      'Item ID': item_id,
      'Collection ID': brand_id
    });

    const newArr = tempShopItems.filter((object) => {
      return !(object.brand_id === brand_id && object.item_id === item_id);
    });

    setCartItems(newArr);
  };
  const signedTransaction = (unsignedTransactionHex, witnessSetHex) => {
    let unsignedTx = decodeHex(unsignedTransactionHex);
    const witnessSet = decodeHex(witnessSetHex);
    unsignedTx[1] = witnessSet;
    const signedTx = unsignedTx;
    let encoder = new Encoder({ useTag259ForMaps: false });
    const signedTxBuffer = encoder.encode(signedTx);
    const signedTxHex = uint8ArrayToHex(signedTxBuffer);
    return signedTxHex;
  };
  function decodeHex(hex) {
    const buffer = new Uint8Array(hex.match(/.{1,2}/g).map((byte) => parseInt(byte, 16)));
    const decoder = new Decoder({
      mapsAsObjects: false
    });
    return decoder.decode(buffer);
  }
  function uint8ArrayToHex(uint8Array) {
    return Array.prototype.map
      .call(uint8Array, function (byte) {
        return ('0' + byte.toString(16)).slice(-2);
      })
      .join('');
  }
  function cartTotal() {
    let totalAmount = 0;
    for (let index = 0; index < cartItems.length; index++) {
      const element = cartItems[index];
      var itemPrice = element.price.substring(element.price.indexOf('-') + 2);
      totalAmount += parseInt(itemPrice);
    }
    return totalAmount;
  }

  async function handleStripeCheckout() {
    if (isAuthenticated) {
      try {
        const accessToken = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: 'ffclient:play'
        });

        // Prepare the checkout items in the expected format
        var checkoutItems = {
          items: createCheckoutItems()
        };

        const response = await API.stripeCheckoutSession(accessToken, checkoutItems);

        if (response.status !== 200) {
          window.alert('Error during checkout');
          return;
        }

        const sessionData = await response.json();
        // Redirect to Stripe checkout using the URL provided in the session response
        window.location = sessionData.url;
      } catch (error) {
        console.error('Error during Stripe checkout:', error);
        window.alert('Error during checkout');
      }
    }
  }

  async function signNami() {
    Mixpanel.track('Wallet Selected', {
      Wallet: 'nami'
    });

    if (!window.cardano || !window.cardano.nami) {
      window.open('https://namiwallet.io/', '_blank');
      return;
    }

    if (!isAuthenticated) {
      console.error('Not authenticated, cannot add wallet.');
    }
    setSelectedWallet('Nami');
    // Forward-compatibility shim.
    if (window.cardano && !window.cardano.nami) {
      // Better way?
      window.cardano.nami = window.cardano; // Test this
    }

    // Make sure nami is enabled.
    const api = await window.cardano.nami.enable();

    // Get the wallet.
    var addresses = await api.getUsedAddresses();
    if (!addresses.length) {
      console.log('No wallets found.');
      return;
    }
    var address = addresses[0];

    if (!wallets.some((wallet) => wallet.address === address)) {
      // Sign
      var hex = new Buffer.from(
        'By signing this note, I verify that I am the owner of this wallet. I agree to share my public wallet address with Future Fest and connect it with my Future Fest account so I can use my NFTs in-game.'
      ).toString('hex');
      try {
        var coseSign = await api.signData(address, hex);
        var networkId = await api.getNetworkId();
      } catch (error) {
        console.log('error: ');
        console.log(error);
        return;
      }

      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: 'ffclient:play'
      });
      const response = await API.addWallet(accessToken, {
        cose: coseSign,
        wallet: 'nami',
        network_id: networkId
      });
      if (response.status !== 200) {
        alert('Sorry, there was an issue adding this wallet. Please let the devs know on discord.');
        return;
      }

      const data = await response.json();
      setWallets(data.wallets);
      setIsWalletConnected(true);
    }
  }
  async function signEternl() {
    Mixpanel.track('Wallet Selected', {
      Wallet: 'eternl'
    });

    if (!window.cardano || !window.cardano.eternl) {
      window.open('https://eternl.io/', '_blank');
      return;
    }

    if (!isAuthenticated) {
      console.error('Not authenticated, cannot add wallet.');
    }
    setSelectedWallet('Eternl');

    const api = await window.cardano.eternl.enable();

    // Get the wallet
    var addresses = await api.getUnusedAddresses();
    if (!addresses.length) {
      console.log('No wallets found.');
      return;
    }
    var address = addresses[0];
    if (!wallets.some((wallet) => wallet.address === address)) {
      // Sign
      try {
        var coseSign = await api.signData(
          address,
          'By signing this note, I verify that I am the owner of this wallet. I agree to share my public wallet address with Future Fest and connect it with my Future Fest account so I can use my NFTs in-game.'
        );
        var networkId = await api.getNetworkId();
      } catch (error) {
        console.log('error: ');
        console.log(error);
        return;
      }

      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: 'ffclient:play'
      });
      const response = await API.addWallet(accessToken, {
        cose: coseSign,
        wallet: 'eternl',
        network_id: networkId
      });
      if (response.status !== 200) {
        alert('Sorry, there was an issue adding this wallet. Please let the devs know on discord.');
        return;
      }

      const data = await response.json();
      setWallets(data.wallets);
      setIsWalletConnected(true);
    }
  }
  async function signYoroi() {
    Mixpanel.track('Wallet Selected', {
      Wallet: 'yoroi'
    });

    if (!window.cardano || !window.cardano.yoroi) {
      window.open('https://yoroi-wallet.com/', '_blank');
      return;
    }

    if (!isAuthenticated) {
      console.error('Not authenticated, cannot add wallet.');
    }
    setSelectedWallet('Yoroi');
    // Make sure Yoroi is enabled.
    const api = await window.cardano.yoroi.enable();

    // Get the wallet
    var addresses = await api.getUnusedAddresses();
    if (!addresses.length) {
      console.log('No wallets found.');
      return;
    }
    var address = addresses[0];
    if (!wallets.some((wallet) => wallet.address === address)) {
      // Sign
      try {
        var coseSign = await api.signData(
          address,
          'By signing this note, I verify that I am the owner of this wallet. I agree to share my public wallet address with Future Fest and connect it with my Future Fest account so I can use my NFTs in-game.'
        );
        var networkId = await api.getNetworkId();
      } catch (error) {
        console.log('error: ');
        console.log(error);
        return;
      }

      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: 'ffclient:play'
      });
      const response = await API.addWallet(accessToken, {
        cose: coseSign,
        wallet: 'yoroi',
        network_id: networkId
      });
      if (response.status !== 200) {
        alert('Sorry, there was an issue adding this wallet. Please let the devs know on discord.');
        return;
      }

      const data = await response.json();
      setWallets(data.wallets);
      setIsWalletConnected(true);
    }
  }
  async function signGeroWallet() {
    Mixpanel.track('Wallet Selected', {
      Wallet: 'gerowallet'
    });

    if (!window.cardano || !window.cardano.gerowallet) {
      window.open('https://gerowallet.io/', '_blank');
      return;
    }

    if (!isAuthenticated) {
      console.error('Not authenticated, cannot add wallet.');
    }
    setSelectedWallet('GeroWallet');
    // Make sure that gero wallet is enabled.
    const api = await window.cardano.gerowallet.enable();

    // Get the wallet
    var addresses = await api.getUnusedAddresses();
    if (!addresses.length) {
      console.log('No wallets found.');
      return;
    }
    var address = addresses[0];
    if (!wallets.some((wallet) => wallet.address === address)) {
      // Sign
      try {
        var coseSign = await api.signData(
          address,
          'By signing this note, I verify that I am the owner of this wallet. I agree to share my public wallet address with Future Fest and connect it with my Future Fest account so I can use my NFTs in-game.'
        );
        var networkId = await api.getNetworkId();
      } catch (error) {
        console.log('error: ');
        console.log(error);
        return;
      }

      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: 'ffclient:play'
      });
      const response = await API.addWallet(accessToken, {
        cose: coseSign,
        wallet: 'gerowallet',
        network_id: networkId
      });
      if (response.status !== 200) {
        alert('Sorry, there was an issue adding this wallet. Please let the devs know on discord.');
        return;
      }

      const data = await response.json();
      setWallets(data.wallets);
      setIsWalletConnected(true);
    }
  }
  async function signLace() {
    Mixpanel.track('Wallet Selected', {
      Wallet: 'lace'
    });

    if (!window.cardano || !window.cardano.lace) {
      window.open('https://www.lace.io/', '_blank');
      return;
    }

    if (!isAuthenticated) {
      console.error('Not authenticated, cannot add wallet.');
    }
    setSelectedWallet('Lace');

    // Make sure lace is enabled.
    const api = await window.cardano.lace.enable();

    // Get the Lace wallet.
    var addresses = await api.getUnusedAddresses();
    if (!addresses.length) {
      console.log('No wallets found.');
      return;
    }
    var address = addresses[0];
    if (!wallets.some((wallet) => wallet.address === address)) {
      // Sign
      try {
        var coseSign = await api.signData(
          address,
          'By signing this note, I verify that I am the owner of this wallet. I agree to share my public wallet address with Future Fest and connect it with my Future Fest account so I can use my NFTs in-game.'
        );
        var networkId = await api.getNetworkId();
      } catch (error) {
        console.log('error: ');
        console.log(error);
        return;
      }

      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: 'ffclient:play'
      });
      const response = await API.addWallet(accessToken, {
        cose: coseSign,
        wallet: 'lace',
        network_id: networkId
      });
      if (response.status !== 200) {
        alert('Sorry, there was an issue adding this wallet. Please let the devs know on discord.');
        return;
      }

      const data = await response.json();
      setWallets(data.wallets);
      setIsWalletConnected(true);
    }
  }
  async function signFlint() {
    Mixpanel.track('Wallet Selected', {
      Wallet: 'flint'
    });

    if (!window.cardano || !window.cardano.flint) {
      window.open('https://flint-wallet.com/', '_blank');
      return;
    }

    if (!isAuthenticated) {
      console.error('Not authenticated, cannot add wallet.');
    }
    setSelectedWallet('Flint');

    // Make sure Flint is enabled.
    const api = await window.cardano.flint.enable();

    // Get the Flint wallet.
    var addresses = await api.getUnusedAddresses();
    if (!addresses.length) {
      console.log('No wallets found.');
      return;
    }
    var address = addresses[0];
    if (!wallets.some((wallet) => wallet.address === address)) {
      // Sign
      try {
        var coseSign = await api.signData(
          address,
          'By signing this note, I verify that I am the owner of this wallet. I agree to share my public wallet address with Future Fest and connect it with my Future Fest account so I can use my NFTs in-game.'
        );
        var networkId = await api.getNetworkId();
      } catch (error) {
        console.log('error: ');
        console.log(error);
        return;
      }

      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: 'ffclient:play'
      });
      const response = await API.addWallet(accessToken, {
        cose: coseSign,
        wallet: 'flint',
        network_id: networkId
      });
      if (response.status !== 200) {
        alert('Sorry, there was an issue adding this wallet. Please let the devs know on discord.');
        return;
      }

      const data = await response.json();
      setWallets(data.wallets);
      setIsWalletConnected(true);
    }
  }

  function createCheckoutItems() {
    var checkoutItems = [];
    for (let index = 0; index < cartItems.length; index++) {
      const element = cartItems[index];
      const data = {
        item_id: element.item_id,
        brand_id: element.brand_id
      };
      checkoutItems.push(data);
    }
    return checkoutItems;
  }

  async function checkoutCartItems() {
    if (cartItems.length > 0) {
      var checkoutItems = createCheckoutItems();

      var address;
      var addresses;
      var api;
      if (!isAuthenticated) {
        await loginWithPopup();
      }
      if (selectedWallet === 'Nami') {
        if (!window.cardano || !window.cardano.nami) {
          window.open('https://namiwallet.io/', '_blank');
          return;
        }

        // Forward-compatibility shim.
        if (window.cardano && !window.cardano.nami) {
          // Better way?
          window.cardano.nami = window.cardano;
        }

        // Make sure nami is enabled.
        api = await window.cardano.nami.enable();
      } else if (selectedWallet === 'Eternl') {
        if (!window.cardano || !window.cardano.eternl) {
          window.open('https://eternl.io/', '_blank');
          return;
        }

        api = await window.cardano.eternl.enable();
      } else if (selectedWallet === 'Yoroi') {
        if (!window.cardano || !window.cardano.yoroi) {
          window.open('https://yoroi-wallet.com/', '_blank');
          return;
        }

        api = await window.cardano.yoroi.enable();
      } else if (selectedWallet === 'GeroWallet') {
        if (!window.cardano || !window.cardano.gerowallet) {
          window.open('https://gerowallet.io/', '_blank');
          return;
        }

        api = await window.cardano.gerowallet.enable();
      } else if (selectedWallet === 'Lace') {
        if (!window.cardano || !window.cardano.lace) {
          window.open('https://lace.io/', '_blank');
          return;
        }

        api = await window.cardano.lace.enable();
      } else if (selectedWallet === 'Flint') {
        if (!window.cardano || !window.cardano.flint) {
          window.open('https://flint-wallet.com/', '_blank');
          return;
        }

        api = await window.cardano.flint.enable();
      }

      // Get the wallet
      addresses = await api.getUsedAddresses();
      if (!addresses.length) {
        console.log('No wallets found.');
        return;
      }
      address = addresses[0];
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: 'ffclient:play'
      });
      const response = await API.postPurchaseItems(accessToken, {
        cart_items: checkoutItems,
        source_wallet_address: address
      });
      if (response.status !== 200) {
        toast.warning(
          'Error purchasing the item. Please check to make sure you have enough ADA in your active wallet. Let the devs on Discord know for more assistance.'
        );
        return;
      }
      const data = await response.json();
      var witnessSetHex;
      try {
        witnessSetHex = await api.signTx(data.cbor);
      } catch (error) {
        console.error('error: ', error);
        if (error.code === -1) {
          // Inputs do not conform to this spec or are otherwise invalid.
          toast.error('Error purchasing items.');
        }
        // error code 2 means user canceled the purchase
        return;
      }
      const signedTxHex = signedTransaction(data.cbor, witnessSetHex);

      const signedTransactionResponse = await API.signedPurchasedTransaction(accessToken, {
        cart_items: checkoutItems,
        signed_tx: signedTxHex
      });
      if (signedTransactionResponse.status !== 204) {
        toast.error('Error purchasing items.');
        return;
      }
      setIsPurchasedSuccessfully(true);
    }
  }

  return (
    <div className="cart-wrapper">
      {isPurchasedSuccessfully ? (
        <div className="purchase-msg">
          <span className="thanks-msg">Thank you for your purchase!</span>
          <span className="confirmation-msg"></span>
          <button className="back-to-store-btn">
            <span>
              <a className="back-to-store-btn-txt" href="/shop">
                Back to Store
              </a>
            </span>
          </button>
        </div>
      ) : (
        <div className="cart-container">
          <div className="left-content">
            <h1 className="cart-heading">Checkout</h1>
            <div className="checkout-items">
              {cartItems.map((item, index) => (
                <div className="cart-items" key={index}>
                  <div className="cart-item">
                    <img className="item-image" src={item.image_url} alt={item.name} />
                    <div className="item-details">
                      <span className="cart-item-name">{item.name}</span>
                      <span
                        className="item-remove-button"
                        onClick={() => removeCartItem(item.brand_id, item.item_id)}
                      >
                        Remove
                      </span>
                    </div>
                  </div>
                  <div className="cart-item-price">
                    <span>{item.price}</span>
                  </div>
                </div>
              ))}
            </div>
            {cartItems.length !== 0 ? (
              <div>
                <hr className="divider" />
                <div className="totaling">
                  <span className="totaling-title">Total due</span>
                  <span className="total-amount">₳{cartTotal()}</span>
                </div>
              </div>
            ) : (
              <div className="empty-cart">
                <p className="empty-cart-text">No items in cart.</p>
              </div>
            )}
          </div>

          <div className="right-content">
            <Tabs
              disableUpDownKeys={true}
              className="payment"
              selectedTabClassName="payment_tab--selected"
              selectedTabPanelClassName="payment_tab-panel--selected"
            >
              {/* <TabList className="payment_tabs">
                <Tab className="payment_tab">
                  <header>Pay with ADA</header>
                </Tab>
                <Tab disabled={true} className="payment_tab">
                  <header>Pay With Card</header>
                </Tab>
              </TabList> */}
              <TabPanel className="payment_tab-panel">
                <div className="payment-mode">Select a wallet</div>
                <div className="wallet-icons">
                  <img
                    className={`wallet-icon${selectedWallet === 'Nami' ? ' wallet-highlited' : ''}`}
                    src={NamiIcon}
                    alt="Nami"
                    onClick={() => signNami()}
                  />
                  {eternlWalletEnabled && (
                    <img
                      className={`wallet-icon${
                        selectedWallet === 'Eternl' ? ' wallet-highlited' : ''
                      }`}
                      src={EternlIcon}
                      alt="Eternl"
                      onClick={() => signEternl()}
                    />
                  )}
                  <img
                    className={`wallet-icon${
                      selectedWallet === 'Yoroi' ? ' wallet-highlited' : ''
                    }${yoroiWalletEnabled ? '' : ' hidden'}`}
                    src={YoroiIcon}
                    alt="Yoroi"
                    onClick={() => signYoroi()}
                  />
                  <img
                    className={`wallet-icon${
                      selectedWallet === 'GeroWallet' ? ' wallet-highlited' : ''
                    }${geroWalletEnabled ? '' : ' hidden'}`}
                    src={GeroWalletIcon}
                    alt="GeroWallet"
                    onClick={() => signGeroWallet()}
                  />
                  <img
                    className={`wallet-icon${selectedWallet === 'Lace' ? ' wallet-highlited' : ''}${
                      laceWalletEnabled ? '' : ' hidden'
                    }`}
                    src={LaceIcon}
                    alt="Lace"
                    onClick={() => signLace()}
                  />
                  <img
                    className={`wallet-icon${
                      selectedWallet === 'Flint' ? ' wallet-highlited' : ''
                    }${flintWalletEnabled ? '' : ' hidden'}`}
                    src={FlintIcon}
                    alt="Flint"
                    onClick={() => signFlint()}
                  />
                </div>
                {isWalletConnected && cartItems.length > 0 && (
                  <button className="pay" onClick={checkoutCartItems}>
                    <span className="pay-text">Pay ₳{cartTotal()}</span>
                  </button>
                )}
              </TabPanel>
              <div className="payment-mode">or</div>
              <br></br>
              <div>
                <button className="" alt="Stripe" onClick={() => handleStripeCheckout()}>
                  Stripe
                </button>
              </div>
              <TabPanel className="payment_tab-panel"></TabPanel>
            </Tabs>
          </div>
        </div>
      )}
    </div>
  );
};

export default Cart;
