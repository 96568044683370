import React, { createContext, useContext, useState } from 'react';

const PrimaryCurrencyContext = createContext({
  primaryCurrencyAmount: 0,
  setPrimaryCurrencyAmount: () => {},
  isBalanceFetched: false,
  setIsBalanceFetched: () => {}
});

export const PrimaryCurrencyProvider = ({ children }) => {
  const [primaryCurrencyAmount, setPrimaryCurrencyAmount] = useState(0);
  const [isBalanceFetched, setIsBalanceFetched] = useState(false);

  const value = {
    primaryCurrencyAmount,
    setPrimaryCurrencyAmount,
    isBalanceFetched,
    setIsBalanceFetched
  };

  return (
    <PrimaryCurrencyContext.Provider value={value}>{children}</PrimaryCurrencyContext.Provider>
  );
};

export const usePrimaryCurrency = () => useContext(PrimaryCurrencyContext);

export default PrimaryCurrencyProvider;
