import React, { useState, useEffect } from 'react';
import './Storefront.css';

const EditStorefront = ({ storefrontDetails, editStorefrontInfo, handleHeroImageUpload }) => {
  const [heroTitle, setHeroTitle] = useState('');
  const [heroSubtitle, setHeroSubtitle] = useState('');
  const [heroImageUrl, setHeroImageUrl] = useState('');

  useEffect(() => {
    setHeroTitle(storefrontDetails.heroTitle);
    setHeroSubtitle(storefrontDetails.heroSubtitle);
  }, [storefrontDetails]);

  const handleUpload = async (e) => {
    const presignedUrl = await handleHeroImageUpload(e);
    if (presignedUrl) {
      setHeroImageUrl(presignedUrl);
    } else {
      return window.alert('Failed to upload the image');
    }
  };

  return (
    <div className="info-container">
      <h3>Update Storefront</h3>
      <div className="input-container">
        <label htmlFor="inputTitle">Title:</label>
        <input
          id="inputTitle"
          type="text"
          value={heroTitle}
          onChange={(e) => setHeroTitle(e.target.value)}
        ></input>
      </div>
      <div className="input-container">
        <label htmlFor="inputSubtitle">Subtitle:</label>
        <input
          id="inputSubtitle"
          type="text"
          value={heroSubtitle}
          onChange={(e) => setHeroSubtitle(e.target.value)}
        ></input>
      </div>
      <div className="input-container">
        <label htmlFor={'heroImage'}>Upload Image :</label>
        <input id={`heroImage`} type="file" accept="image/*" onChange={handleUpload} />
      </div>
      <div className="actions-container">
        <button
          className="ff-button"
          onClick={() => editStorefrontInfo(heroTitle, heroSubtitle, heroImageUrl)}
        >
          Update
        </button>
      </div>
    </div>
  );
};

export default EditStorefront;
