import * as React from 'react';
import './Header.css';
import { useAuth0 } from '@auth0/auth0-react';
import VibeCoinIcon from '../images/svg-icons/vibecoin-icon.svg';
// import { CartState } from '../CartContext';
import { usePrimaryCurrency } from '../PrimaryCurrencyContext';
import LoginLogoutButton from '../LoginLogoutButton';
import HeaderLogo from '../images/ff-header-logo.png';

interface HeaderProps {
  // onAdminConsoleClick: () => void;
  // onAccountSettingsClick: () => void;
  // onConnectWalletClick: () => void;
  onEventsClick?: () => void;
  onShopClick?: () => void;
  onCartClick?: () => void;
  onPrimaryCurrencyClick?: () => void;
  // itemsInCart: number;
  wallets?: any[];
  setWallets?: React.Dispatch<React.SetStateAction<any[]>>;
}

/**
 * Primary UI component for displaying a header
 */
const Header: React.FC<HeaderProps> = ({
  onEventsClick,
  onShopClick,
  onCartClick,
  onPrimaryCurrencyClick,
  wallets,
  setWallets
}) => {
  // const { cartItems } = CartState();
  const { isAuthenticated } = useAuth0();
  const { primaryCurrencyAmount, isBalanceFetched } = usePrimaryCurrency();
  return (
    <header
      className="header-container sticky top-0 left-0 py-7 px-5 z-10 w-full clear-both"
    >
      <div className="container mx-auto flex items-center justify-between">
        <div className="flex gap-12">
          <a href="https://www.futurefest.io">
            <img className="h-[36px] w-[154px]" src={HeaderLogo} alt="Future Fest Logo" />
          </a>
          {/* <button
            className="text-primaryColor font-bold text-base font-['Poppins']"
            onClick={onShopClick}
          >
            Shop
          </button> */}
          {/* <button
            className="text-primaryColor font-bold text-base font-['Poppins']"
            onClick={onEventsClick}
          >
            Events
          </button> */}
        </div>
        <div className="text-white font-bold">
          NEW ALPHA BUILD - NFTs & Customizations broked :(
        </div>
        <div className="flex gap-12 items-center">
          {/* <div className="flex">
            {isAuthenticated && isBalanceFetched && onPrimaryCurrencyClick && (
              <button
                className="flex items-center justify-center space-x-1 p-2 rounded-full cursor-pointer hover:bg-gray-100 border border-purple-500"
                onClick={onPrimaryCurrencyClick}
              >
                <img className="w-6 h-6" src={VibeCoinIcon} alt="Vibe Coin Icon" />
                <span className="text-primaryColor text-xs font-bold">
                  {primaryCurrencyAmount}
                </span>
              </button>
            )}
          </div> */}
          {/* <div className="flex">
            <PiShoppingCartSimpleBold
              className="self-center cursor-pointer text-primaryColor"
              onClick={onCartClick}
              size={24}
            />
            {cartItems.length > 0 && (
              <div className="flex rounded-full bg-red-400 w-4 h-4 self-start">
                <span className="text-neutralColor-0 text-xs text-center w-full">
                  {cartItems.length}
                </span>
              </div>
            )}
          </div> */}
          <LoginLogoutButton wallets={wallets} setWallets={setWallets} />
        </div>
      </div>
    </header>
  );
};

export default Header;
