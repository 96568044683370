import React, { useState, useRef, useEffect } from 'react';
import './LoginLogoutButton.css';
import { useAuth0 } from '@auth0/auth0-react';
import AccountSettings from './AccountSettings';
import SettingsIcon from './images/icon-settings.png';
import WalletIcon from './images/icon-wallet.png';
import LogoutIcon from './images/icon-log-out.png';
import { FaCaretDown } from 'react-icons/fa';
import LoginButton from './login';
import Select, { components } from 'react-select';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import ConnectWalletAnvil from './ConnectWalletAnvil';

const LoginLogoutButton = ({ wallets, setWallets }) => {
  const [showAccountSettingsModal, setShowAccountSettingsModal] = useState(false);
  const [showAddWalletModal, setShowAddWalletModal] = useState(false);
  const { isAuthenticated, user, logout } = useAuth0();
  const navigate = useNavigate();
  const [fontSize, setFontSize] = useState(16);
  const usernameRef = useRef(null);

  useEffect(() => {
    if (isAuthenticated && usernameRef.current) {
      const containerWidth = 180;
      let currentFontSize = 16;

      while (usernameRef.current.scrollWidth > containerWidth && currentFontSize > 8) {
        currentFontSize--;
        setFontSize(currentFontSize);
      }
    }
  }, [isAuthenticated, user]);

  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <components.ValueContainer {...props}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%'
          }}
        >
          {isAuthenticated && (
            <>
              <span
                ref={usernameRef}
                style={{
                  color: '#FFFFFF',
                  fontSize: `${fontSize}px`,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: '150px'
                }}
              >
                {user['https://futurefest.io/username']}
              </span>
              <FaCaretDown color="#FFFFFF" style={{ marginLeft: '5px' }} />
            </>
          )}

          {/* Render children but exclude the selected value */}
          {React.Children.map(children, (child) =>
            child && child.type !== components.SingleValue && child.type !== components.Placeholder
              ? child
              : null
          )}
        </div>
      </components.ValueContainer>
    );
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: '#000000',
      border: '2px solid #FFFFFF',
      boxShadow: 'unset',
      fontFamily: 'Poppins, sans-serif',
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '24px',
      width: 180,
      color: '#FFFFFF'
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#000000',
      color: '#FFFFFF',
      zIndex: 100000,
      border: '1px solid #333333'
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#333333' : '#000000',
      color: '#FFFFFF',
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      fontFamily: 'Poppins, sans-serif',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '21px',
      padding: '8px',
      ':active': {
        backgroundColor: '#4CAF50'
      }
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#FFFFFF'
    })
  };

  const dropdownOptions = [
    ...(isAuthenticated && user['https://futurefest.io/roles']?.includes('Admin')
      ? [{ value: 'admin', label: 'Admin Console', icon: SettingsIcon }]
      : []),
    // { value: 'account', label: 'Account Settings', icon: SettingsIcon },
    // { value: 'wallet', label: 'Connect Wallet', icon: WalletIcon },
    { value: 'logout', label: 'Logout', icon: LogoutIcon }
  ].map((option) => ({
    value: option.value,
    label: (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img src={option.icon} alt={`${option.label} icon`} style={{ marginRight: 10 }} />
        {option.label}
      </div>
    )
  }));

  const handleDropdownChange = (option) => {
    switch (option.value) {
      case 'admin':
        navigate('/admin');
        break;
      case 'account':
        setShowAccountSettingsModal(true);
        break;
      case 'wallet':
        setShowAddWalletModal(true);
        break;
      case 'logout':
        logout({ returnTo: window.location.origin });
        break;
      default:
        break;
    }
  };

  return (
    <div className="login-buttons">
      <LoginButton />
      {isAuthenticated && (
        <Select
          options={dropdownOptions}
          onChange={handleDropdownChange}
          styles={customStyles}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
            ValueContainer: CustomValueContainer
          }}
          isSearchable={false}
        />
      )}
      <Modal
        isOpen={showAccountSettingsModal}
        parentSelector={() => document.querySelector('.App')}
        className="AccountSettingsModal__Content ff-modal-content"
        overlayClassName="AccountSettingsModal__Overlay ff-overlay"
        onRequestClose={() => setShowAccountSettingsModal(false)}
        shouldCloseOnOverlayClick={true}
      >
        <AccountSettings wallets={wallets} setWallets={setWallets} />
      </Modal>
      <Modal
        isOpen={showAddWalletModal}
        parentSelector={() => document.querySelector('.App')}
        className="AddWalletModal__Content ff-modal-content"
        overlayClassName="AddWalletModal__Overlay ff-overlay"
        onRequestClose={() => setShowAddWalletModal(false)}
        shouldCloseOnOverlayClick={true}
      >
        <ConnectWalletAnvil
          wallets={wallets}
          setWallets={setWallets}
          callback={() => setShowAddWalletModal(false)}
        />
      </Modal>
    </div>
  );
};

export default LoginLogoutButton;
