import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import API from '../API';
import VibeCoinIcon from './images/svg-icons/vibecoin-icon.svg';
import { useCurrencyBundle } from './CurrencyBundleContext';
import './CurrencyBundleButtons.css';

const CurrencyBundleButtons = () => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [currencyBundles, setCurrencyBundles] = useState([]);
  const { selectedBundle, setSelectedBundle } = useCurrencyBundle();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!isAuthenticated) {
        setError('User not authenticated.');
        setLoading(false);
        return;
      }

      setLoading(true);
      try {
        const cachedData = sessionStorage.getItem('currencyBundles');
        if (cachedData) {
          setCurrencyBundles(JSON.parse(cachedData));
          setLoading(false);
          return;
        }

        const accessToken = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: 'ffclient:play'
        });

        const response = await API.getCurrencyBundles(accessToken);
        if (response.status === 200) {
          const data = await response.json();
          sessionStorage.setItem('currencyBundles', JSON.stringify(data.currency_bundles));
          setCurrencyBundles(data.currency_bundles);
        } else {
          throw new Error('Failed to fetch currency bundles');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [getAccessTokenSilently, isAuthenticated]);

  const handleSelectBundle = (bundle) => {
    setSelectedBundle(bundle);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="currency-bundle-container">
      {currencyBundles
        .slice() // Create a copy to avoid mutating the original array
        .sort((a, b) => a.currency_amount - b.currency_amount) // Sort by currency_amount
        .map((bundle) => (
          <button
            key={bundle.stripe_price_id}
            className={`currency-bundle ${
              selectedBundle?.stripe_price_id === bundle.stripe_price_id ? 'selected' : ''
            }`}
            onClick={() => handleSelectBundle(bundle)}
          >
            <img
              className="currency-bundle-img"
              src={bundle.image_urls[0]}
              alt={`Select ${bundle.display_name}`}
            />
            <div className="currency-amount-overlay">
              <img className="amount-icon" src={VibeCoinIcon} alt="Amount Icon" />
              {bundle.currency_amount}
            </div>
            <div className="bundle-price">${bundle.unit_price.toFixed(2)}</div>
          </button>
        ))}
    </div>
  );
};

export default CurrencyBundleButtons;
