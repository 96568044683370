import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import API from '../API';
import { useCurrencyBundle } from './CurrencyBundleContext';
import VisaIcon from './images/svg-icons/visa-icon.svg';
import './CheckoutWithCreditCardButton.css';

const CheckoutWithCreditCardButton = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { getCurrencyPurchase } = useCurrencyBundle();

  const handleCheckout = async () => {
    const currencyPurchase = getCurrencyPurchase();
    if (!currencyPurchase) {
      alert('Please select a bundle before checking out.');
      return;
    }

    try {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: 'ffclient:play'
      });

      const response = await API.stripeCurrencyCheckout(accessToken, currencyPurchase);
      if (response.status !== 200) {
        alert('Error during checkout');
        return;
      }

      const sessionData = await response.json();
      window.location = sessionData.url;
    } catch (error) {
      alert('Error during checkout');
    }
  };

  return (
    <button className="checkout-with-credit-card-btn" onClick={handleCheckout}>
      <img src={VisaIcon} alt="Checkout Icon" />
      Pay with Credit Card
    </button>
  );
};

export default CheckoutWithCreditCardButton;
