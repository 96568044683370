import React, { useEffect } from 'react';
import './IVSPlayer.css';
import { Helmet } from 'react-helmet';

const IVSPlayer = ({ stream_url }) => {
  useEffect(() => {
    function loadPlayer() {
      // Get playback URL from Amazon IVS API
      var PLAYBACK_URL = stream_url;
      // Register Amazon IVS as playback technology for Video.js
      window.registerIVSTech(window.videojs);
      window.registerIVSQualityPlugin(window.videojs); // where videojs is the video.js variable
      // Initialize player
      var player = window.videojs(
        'amazon-ivs-videojs',
        {
          techOrder: ['AmazonIVS']
        },
        () => {
          // Play stream
          player.enableIVSQualityPlugin();
          player.src(PLAYBACK_URL);
        }
      );
    }
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://cdnjs.cloudflare.com/ajax/libs/video.js/7.6.6/video.min.js';
    //For head
    document.head.appendChild(script);

    const script2 = document.createElement('script');
    script2.async = true;
    script2.src = 'https://player.live-video.net/1.2.0/amazon-ivs-videojs-tech.min.js';
    //For head
    document.head.appendChild(script2);

    const script3 = document.createElement('script');
    script3.async = true;
    script3.src = 'https://player.live-video.net/1.8.0/amazon-ivs-quality-plugin.min.js';
    //For head
    document.head.appendChild(script3);

    var otherLoaded = 0;
    script.addEventListener('load', () => {
      if (otherLoaded === 2) {
        loadPlayer();
      } else {
        otherLoaded++;
      }
    });
    script2.addEventListener('load', () => {
      if (otherLoaded === 2) {
        loadPlayer();
      } else {
        otherLoaded++;
      }
    });
    script3.addEventListener('load', () => {
      if (otherLoaded === 2) {
        loadPlayer();
      } else {
        otherLoaded++;
      }
    });
  }, [stream_url]);

  return (
    <div className="fullscreen">
      <Helmet>
        <link
          href="https://cdnjs.cloudflare.com/ajax/libs/video.js/7.6.6/video-js.css"
          rel="stylesheet"
        />
      </Helmet>
      <div className="video-container">
        <video
          id="amazon-ivs-videojs"
          className="video-js vjs-4-3 vjs-big-play-centered"
          controls={true}
          autoPlay={true}
          playsInline={true}
        ></video>
      </div>
    </div>
  );
};

export default IVSPlayer;
