import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Navigate } from 'react-router-dom';

const RequireAuth = ({ role, children }) => {
  const { isAuthenticated, isLoading, user } = useAuth0();
  if (isLoading) {
    return <div>Loading ...</div>;
  }

  if (!isAuthenticated || (role && !user['https://futurefest.io/roles']?.includes(role))) {
    return <Navigate to="/" replace />;
  }

  return children;
};

export default RequireAuth;
