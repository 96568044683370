import React from 'react';

export const SearchBar = ({
  searchInput,
  setSearchInput,
  filtered,
  setFiltered,
  filterFunction,
  setGlobalFilter
}) => {
  const handleSearch = (event) => {
    setSearchInput(event.target.value);
    setFiltered(true);
    setGlobalFilter(event.target.value);
    // handleChange(event.target.value);
    return event.target.value;
  };

  // if !searchInput, filtered = false, show all collections
  // if searchInput, setFiltered(true), filtered=true, show only collections where collection.name contains searchInput
  // set delay on filtering
  // onkeydown, wait 500 milliseconds and then search
  return (
    <div className="search-form">
      <input
        className="collections-table-search-bar"
        type="text"
        placeholder="Search by Collection"
        onChange={(e) => handleSearch(e)}
        // onChange={filterFunction()}
        value={searchInput}
      ></input>
    </div>
  );
};
