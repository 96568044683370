import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import AdminConsole from './components/Admin/AdminConsole';
import Event from './components/Event';
import Modal from 'react-modal';
import RequireAuth from './components/RequireAuth';
import { useState, useEffect } from 'react';
import FeaturedEvents from './components/FeaturedEvents';
import CartContext from './components/CartContext';
import PrimaryCurrencyProvider from './components/PrimaryCurrencyContext';
import Toast from './components/Toast';
import ProductDetailsPage from './pages/ProductDetailsPage';
import ShopPage from './pages/ShopPage';
import SetUsernamePage from './pages/SetUsernamePage';
import { useAuth0 } from '@auth0/auth0-react';
import Mixpanel from './components/Mixpanel';
Modal.setAppElement('#root');

function App() {
  const [wallets, setWallets] = useState([]);
  const shopEnabled = false;

  const { isAuthenticated, user } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      Mixpanel.identify(user.sub);
      Mixpanel.people.set('$email', user.email);
      Mixpanel.people.set('$name', user['https://futurefest.io/username']);
    } else {
      Mixpanel.reset();
    }
  }, [isAuthenticated, user]);

  return (
    <PrimaryCurrencyProvider>
      <CartContext>
        <Toast />
        <Router>
          <div className="App">
            <Helmet>
              <title>Future Fest</title>
            </Helmet>
            <Routes>
              <Route
                path="/"
                element={<FeaturedEvents wallets={wallets} setWallets={setWallets} />}
              />
              {shopEnabled && (
                <>
                  <Route
                    path="/shop"
                    element={<ShopPage wallets={wallets} setWallets={setWallets} />}
                  />
                  <Route
                    path="/shop/collections/:collectionId/products/:productId"
                    element={<ProductDetailsPage />}
                  />
                </>
              )}
              <Route
                path="/events/:eventId"
                element={
                  // <RequireAuth>
                  <Event wallets={wallets} setWallets={setWallets} shopEnabled={shopEnabled} />
                  // </RequireAuth>
                }
              />
              <Route path="/set-username" element={<SetUsernamePage />} />
              <Route
                path="/admin"
                element={
                  <RequireAuth role="Admin">
                    <AdminConsole wallets={wallets} setWallets={setWallets} />
                  </RequireAuth>
                }
              />
            </Routes>
          </div>
        </Router>
      </CartContext>
    </PrimaryCurrencyProvider>
  );
}

export default App;
