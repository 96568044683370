import React, { createContext, useContext, useState } from 'react';

const CurrencyBundleContext = createContext({
  selectedBundle: null,
  setSelectedBundle: () => {},
  getCurrencyPurchase: () => {}
});

export const useCurrencyBundle = () => useContext(CurrencyBundleContext);

export const CurrencyBundleProvider = ({ children }) => {
  const [selectedBundle, setSelectedBundle] = useState(null);

  const value = {
    selectedBundle,
    setSelectedBundle,
    // Helper function to create the currencyPurchase object
    getCurrencyPurchase: () => {
      return selectedBundle
        ? {
            stripe_product_id: selectedBundle.stripe_product_id,
            stripe_price_id: selectedBundle.stripe_price_id
          }
        : null;
    }
  };

  return <CurrencyBundleContext.Provider value={value}>{children}</CurrencyBundleContext.Provider>;
};
