import React from 'react';
import './Storefront.css';
import EditIcon from '../images/svg-icons/edit-icon.svg';
import TrashIcon from '../images/svg-icons/trash-icon.svg';
import ConfirmIcon from '../images/svg-icons/confirm-icon.svg';
import DeleteIcon from '../images/svg-icons/delete-icon.svg';
import OpenArrow from '../images/svg-icons/open-dropdown-icon.svg';
import CloseArrow from '../images/svg-icons/close-dropdown-icon.svg';

export const RowActions = ({ row, table, tableData, editInfo, removeInfo, showExpander }) => {
  const confirmDelete = (index) => {
    const confirmed = window.confirm('Are you sure you want to delete this item?');
    if (confirmed) {
      removeInfo(tableData[index]);
    }
  };

  return (
    <div className="row-action-button-container">
      {showExpander && (
        <button
          className="row-action-button"
          onClick={row.getToggleExpandedHandler()}
          style={{ cursor: 'pointer' }}
        >
          {row.getIsExpanded() ? (
            <img src={CloseArrow} alt="Close" />
          ) : (
            <img src={OpenArrow} alt="Open" />
          )}
        </button>
      )}
      {row.getIsSelected() ? (
        <div>
          <button
            className="row-action-button"
            onClick={(e) => {
              const unstableTableData = table.options.meta.unstableTableData[row.index];
              editInfo(unstableTableData);
              return row.getToggleSelectedHandler()(e);
            }}
          >
            <img className="confirm-action-button" src={ConfirmIcon} alt="Confirm" />
          </button>
          <button
            className="row-action-button trash-action-button"
            onClick={(e) => {
              table.options.meta.unstableTableData[row.index] = tableData[row.index];
              return row.getToggleSelectedHandler()(e);
            }}
          >
            <img className="delete-action-button" src={DeleteIcon} alt="Delete" />
          </button>
        </div>
      ) : (
        <div>
          <button className="row-action-button" onClick={row.getToggleSelectedHandler()}>
            <img className="edit-action-button" src={EditIcon} alt="Edit" />
          </button>
          <button
            className="row-action-button trash-action-button"
            onClick={() => confirmDelete(row.index)}
          >
            <img className="trash-action-button" src={TrashIcon} alt="Trash" />
          </button>
        </div>
      )}
    </div>
  );
};
