import React, { useState } from 'react';
import './Storefront.css';

const AddNewProduct = ({ createNewProduct, handleProductImageUpload }) => {
  const [productName, setproductName] = useState('');
  const [productImageUrl, setProductImageUrl] = useState('');
  const [productCategory, setProductCategory] = useState('');
  const [productPrice, setProductPrice] = useState('');

  // currently handleUpload is not working for add new product due to lack of item_id - sends empty imageUrl

  const handleUpload = async (e) => {
    const presignedUrl = await handleProductImageUpload(e);
    if (presignedUrl) {
      setProductImageUrl(presignedUrl);
    } else {
      return window.alert('Failed to upload the image');
    }
  };

  return (
    <div className="info-container">
      <h3>Add New Product</h3>
      <div className="input-container">
        <label htmlFor="productName">Product Name:</label>
        <input
          id="productName"
          type="text"
          value={productName}
          onChange={(e) => setproductName(e.target.value)}
        ></input>
      </div>
      <div className="input-container">
        <label htmlFor="productCategory">Product Category:</label>
        <input
          id="productCategory"
          type="text"
          value={productCategory}
          onChange={(e) => setProductCategory(e.target.value)}
        ></input>
      </div>

      <div className="input-container">
        <label htmlFor="productPrice">Product Price:</label>
        <input
          id="productPrice"
          type="text"
          value={productPrice}
          onChange={(e) => setProductPrice(Number(e.target.value))}
        ></input>
      </div>
      <div className="input-container">
        <label htmlFor={'productImage'}>Upload Product Image:</label>
        <input id={`productImage`} type="file" accept="image/*" onChange={handleUpload} />
      </div>
      <div className="actions-container">
        <button
          className="ff-button"
          onClick={() =>
            createNewProduct(productName, productImageUrl, productCategory, productPrice)
          }
        >
          Create Product
        </button>
      </div>
    </div>
  );
};

export default AddNewProduct;
