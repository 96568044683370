import React, { useEffect } from 'react';
import Modal from 'react-modal';
import FFIcon from './images/ff-icon.png';

const DesktopClientLoadingModal = ({
  showDesktopClientLoadingModal,
  setShowDesktopClientLoadingModal,
  callback,
  deeplink,
  isAppleSilicon
}) => {
  // Re-render if deeplink is updated
  useEffect(() => {}, [deeplink]);

  async function downloadAndInstall() {
    if (isAppleSilicon) {
      window.location.href = 'https://d2xen1wf0yg342.cloudfront.net/FutureFestLauncher_Beta.dmg';
    } else {
      window.location.href = 'https://d2xen1wf0yg342.cloudfront.net/FutureFestLauncherSetup.exe';
    }
    setShowDesktopClientLoadingModal(false);
    callback();
  }
  async function enterWorld() {
    setShowDesktopClientLoadingModal(false);
    window.location.href = deeplink;
    callback();
  }
  return (
    <Modal
      isOpen={showDesktopClientLoadingModal}
      parentSelector={() => document.querySelector('.enter-world-container')}
      className="RegionSelectionModal__Content ff-modal-content ff-center"
      overlayClassName="RegionSelectionModal__Overlay"
      onRequestClose={() => setShowDesktopClientLoadingModal(false)}
      shouldCloseOnOverlayClick={false}
    >
      <img style={{ objectFit: 'contain' }} src={FFIcon} alt="Future Fest Icon" />
      <h2 className="connect-wallet-title">We've updated our launcher! Please Re-install</h2>
      <p className="connect-wallet-subtitle">
        Reinstall the launcher to version 3.0 before entering the world.
      </p>
      <button className="ff-button ff-button-orange" onClick={downloadAndInstall}>
        Download Launcher 3.0
      </button>
      {/* <p className="connect-wallet-subtitle">------- or -------</p> */}
      <div className="flex items-center justify-between">
        <div className="w-10 h-px bg-neutral-700" /> or <div className="w-10 h-px bg-neutral-700" />
      </div>
      <p className="connect-wallet-subtitle">
        Already have it installed? This will only work above 3.0
      </p>
      <button className="ff-button" onClick={enterWorld}>
        Enter World
      </button>
      <a href="https://www.futurefest.io/support" target="_blank" rel="noopener noreferrer">
        Click here for help
      </a>
    </Modal>
  );
};

export default DesktopClientLoadingModal;
