// WalletSelector.jsx
import React from 'react';
import NamiIcon from './images/Nami Wallet icon.png';
import EternlIcon from './images/Eternl Wallet Icon.png';
import GeroWalletIcon from './images/Gero Wallet Icon.png';
import './CardanoWalletSelector.css';

const CardanoWalletSelector = ({ onSelectWallet }) => {
  return (
    // Placeholders
    <div className="wallet-selector">
      <button onClick={() => onSelectWallet('nami')} className="wallet-option-button">
        <img src={NamiIcon} alt="Nami Wallet" className="wallet-option-icon" />
        <span>Nami</span>
      </button>
      <button onClick={() => onSelectWallet('eternl')} className="wallet-option-button">
        <img src={EternlIcon} alt="Eternl Wallet" className="wallet-option-icon" />
        <span>Eternl</span>
      </button>
      <button onClick={() => onSelectWallet('gero')} className="wallet-option-button">
        <img src={GeroWalletIcon} alt="Gero Wallet" className="wallet-option-icon" />
        <span>Gero Wallet</span>
      </button>
    </div>
  );
};

export default CardanoWalletSelector;
