import React, { useEffect, useState } from 'react';
import './AdminConsole.css';
import API from '../../API';
import { useAuth0 } from '@auth0/auth0-react';
import AppHeader from '../AppHeader';
import EditEvent from './EditEvent';
import EditDeeplink from './EditDeeplink';
// import EditServerInfo from './EditServerInfo';
// import DesktopClient from './DesktopClient.jsx';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import StorefrontManagement from './StorefrontManagement';

// TODO: Rename Deeplink page & move DesktopClient to Active Events?

const AdminConsole = ({ wallets, setWallets }) => {
  const [events, setEvents] = useState([]);
  const [accessToken, setAccessToken] = useState(null);
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    async function getEvents() {
      if (isAuthenticated) {
        const accessToken = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: 'ffclient:play'
        });
        setAccessToken(accessToken);

        const response = await API.adminGetEvents(accessToken);
        if (response.status !== 200) {
          console.log('Error getting events.');
          return;
        }

        const data = await response.json();
        setEvents(data.events);
      }
    }
    getEvents();
  }, [getAccessTokenSilently, isAuthenticated]);

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return (
    isAuthenticated && (
      <div className="fullscreen">
        <AppHeader wallets={wallets} setWallets={setWallets} />
        <Tabs
          disableUpDownKeys={true}
          className="admin-console"
          selectedTabClassName="admin-console__tab--selected"
          selectedTabPanelClassName="admin-console__tab-panel--selected"
        >
          <TabList className="admin-console__tab-list">
            <Tab className="admin-console__tab">Active Events</Tab>
            <Tab className="admin-console__tab">Launcher</Tab>
            {/* <Tab className="admin-console__tab">Server Info</Tab> */}
            {/* <Tab className="admin-console__tab">Desktop Clients</Tab> */}
            <Tab className="admin-console__tab">Manage Storefront</Tab>
          </TabList>
          <TabPanel className="admin-console__tab-panel">
            <h2 className="ff-header">Active Events</h2>
            <br />
            {events.map((event) => (
              <EditEvent event={event} accessToken={accessToken} key={event.id} />
            ))}
          </TabPanel>
          <TabPanel className="admin-console__tab-panel">
            <h2 className="ff-header">Launcher</h2>
            <br />
            <EditDeeplink accessToken={accessToken} />
          </TabPanel>
          {/* <TabPanel className="admin-console__tab-panel">
            <h2 className="ff-header">Server Info</h2>
            <br />
            <EditServerInfo accessToken={accessToken} />
          </TabPanel> */}
          {/* <TabPanel className="admin-console__tab-panel">
            <h2 className="ff-header">Desktop Clients</h2>
            <br />
            <DesktopClient accessToken={accessToken} />
          </TabPanel> */}
          <TabPanel className="admin-console__tab-panel">
            <h2 className="ff-header">Storefront Management</h2>
            <br />
            <StorefrontManagement accessToken={accessToken} />
          </TabPanel>
        </Tabs>
      </div>
    )
  );
};

export default AdminConsole;
