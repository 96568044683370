import React, { useState } from 'react';
import './Storefront.css';

const AddNewCollection = ({ setCollections, createNewCollection }) => {
  const [collectionName, setCollectionName] = useState('');

  const addCollection = async (collectionName) => {
    const updatedCollectionsList = await createNewCollection(collectionName);
    setCollections(updatedCollectionsList);
  };

  return (
    <div className="info-container">
      <h3>Add New Collection</h3>
      <div className="input-container">
        <label htmlFor="collectionName">Collection Name:</label>
        <input
          id="collectionName"
          type="text"
          value={collectionName}
          onChange={(e) => setCollectionName(e.target.value)}
        ></input>
      </div>

      <div className="actions-container">
        <button className="ff-button" onClick={() => addCollection(collectionName)}>
          Create Collection
        </button>
      </div>
    </div>
  );
};

export default AddNewCollection;
