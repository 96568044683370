import React from 'react';
import './Storefront.css';

export const AdminButton = ({ buttonText, handleClick }) => {
  // need to add changeHandler prop and pass it to the onClick
  return (
    <button className="admin-add-button" onClick={handleClick}>
      {buttonText}
    </button>
  );
};
