import React, { useState } from 'react';

export const StripePricesModal = ({ prices, onClose, onConfirm, onPlus }) => {
  const [selectedPriceId, setSelectedPriceId] = useState(prices[0]?.stripe_price_id || '');
  const [selectedPriceUsd, setSelectedPriceUsd] = useState(prices[0]?.amount || 0);

  const handleSelectChange = (e) => {
    const selectedPrice = prices.find((price) => price.stripe_price_id === e.target.value);
    setSelectedPriceId(e.target.value);
    setSelectedPriceUsd(selectedPrice ? selectedPrice.amount : 0);
  };

  const handleNewPriceFormButton = () => {
    onPlus();
    onClose();
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>Select a Price</h2>
        <div>
          <button onClick={handleNewPriceFormButton}>+</button>
          <select onChange={handleSelectChange} value={selectedPriceId}>
            {prices.map((price) => (
              <option key={price.stripe_price_id} value={price.stripe_price_id}>
                {price.amount} USD
              </option>
            ))}
          </select>
        </div>
        <div className="button-container">
          <button onClick={onClose}>Close</button>
          <button
            className="submit-button"
            onClick={() => onConfirm(selectedPriceId, selectedPriceUsd)}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};
