import React, { useState, useEffect } from 'react';
import './Storefront.css';
import API from '../../API';
import { AuthProvider } from '../AuthContext';
import EditStorefront from './EditStorefront';
import CollectionsTable from './CollectionsTable';
import EditCollection from './EditCollection';
import EditProduct from './EditProduct';
import AddNewCollection from './AddNewCollection';
import AddNewProduct from './AddNewProduct';
import Mixpanel from '../Mixpanel';

const StorefrontManagement = ({ accessToken }) => {
  const [storefrontDetails, setStorefrontDetails] = useState({
    heroTitle: '',
    heroSubtitle: '',
    heroImageUrl: ''
  });
  const [collections, setCollections] = useState([]);
  const [selectedCollection, setSelectedCollection] = useState(null);
  const [selectedProduct] = useState(null);

  useEffect(() => {
    Mixpanel.track('Page Viewed', {
      'Page Type': 'Storefront Management'
    });
  }, []);

  // API calls for updating storefront title, subtitle, imageurl
  const editStorefrontInfo = async (title, subtitle, imageUrl) => {
    const response = await API.adminUpdateStorefrontInfo(accessToken, {
      hero_title: title,
      hero_subtitle: subtitle,
      hero_image_url: imageUrl,
      cardano_wallet_address: 'addr1myaddress'
    });
    if (response.status !== 204) {
      console.error('Error updating storefront');
      return;
    }
  };

  const handleHeroImageUpload = async (e) => {
    const response = await API.adminGetShopHeroImagePresignedUrl(accessToken);
    if (response.status !== 200) {
      window.alert('Error getting presignedURL');
      return;
    }
    const data = await response.json();
    let selectedFile = e.target.files[0];
    const s3Response = await fetch(data.presignedUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': 'image/jpeg'
      },
      body: selectedFile
    });
    if (s3Response.status !== 200) {
      window.alert('Failed to upload the image');
      return;
    } else {
      return s3Response.url;
    }
  };

  // API calls for collections
  const createNewCollection = async (collectionName) => {
    // set status to staging by default for createNewCollection
    const response = await API.adminAddNewCollection(accessToken, {
      name: collectionName,
      status: 'staged'
    });

    if (response.status !== 200) {
      window.alert('Error Creating Collection');
      return;
    }
    const newCollection = await response.json();

    const updatedCollections = [...collections, { ...newCollection, products: [] }];
    setCollections(updatedCollections);
    return updatedCollections;
  };

  const editCollectionInfo = async (editedCollection) => {
    const response = await API.adminUpdateCollection(accessToken, {
      collectionId: editedCollection.id,
      name: editedCollection.name,
      status: editedCollection.status,
      sort_order: parseInt(editedCollection.sort_order, 10)
    });
    if (response.status !== 204) {
      console.error('Error updating collection', editedCollection.id);
      return;
    }

    // let tempCollection = { ...selectedCollection };
    // tempCollection.name = editedCollection.name;
    collectionUpdated(editedCollection);
  };

  const removeCollection = async (collection) => {
    const response = await API.adminDeleteCollection(accessToken, { collectionId: collection.id });

    if (response.status !== 204) {
      window.alert('Error Removing Collection');
      return;
    }
    const updatedCollections = collections.filter((item) => item.id !== collection.id);

    setCollections(updatedCollections);
  };

  // helper function to track if collections list has changed
  const collectionUpdated = (updatedCollection) => {
    const tempCollections = collections.map((collection) => {
      if (collection.id === updatedCollection.id) {
        return updatedCollection;
      }
      return collection;
    });
    setCollections(tempCollections);
  };

  // API calls for products

  const createNewProduct = async ({
    productName,
    productImageUrl,
    productCategory,
    productPrice,
    // productPriceUSD,
    collection_id
  }) => {
    // console.log('createNewProduct productCollectionId', productCollectionId);
    const response = await API.adminAddNewProduct(accessToken, {
      collectionId: collection_id,
      name: productName,
      image_url: productImageUrl,
      category: productCategory,
      status: 'staged',
      price_lovelace: productPrice
      // price_usd: productPriceUSD
    });
    if (response.status !== 200) {
      window.alert('Error creating product');
      return;
    }

    const newProduct = await response.json();

    productCreated({
      id: newProduct.id,
      item_id: newProduct.item_id,
      name: productName,
      image_url: productImageUrl,
      category: productCategory,
      price: productPrice,
      price_usd: 0,
      status: 'staged',
      collection_id: collection_id
    });
  };

  const editProductInfo = async (
    editedProduct
    // productId,
    // productItemId,
    // productName,
    // productCategory,
    // productPrice,
    // productStatus,
    // productImageUrl
  ) => {
    console.log('editedProduct:', editedProduct);
    const response = await API.adminUpdateProduct(accessToken, {
      collectionId: editedProduct.collection_id,
      productId: editedProduct.item_id,
      name: editedProduct.name,
      description: editedProduct.description,
      category: editedProduct.category,
      price_lovelace: parseInt(editedProduct.price, 10),
      // price_usd: parseInt(editedProduct.price_usd, 10),
      image_url: editedProduct.image_url,
      status: editedProduct.status,
      stripe_product_id: editedProduct.stripe_product_id,
      stripe_price_id: editedProduct.stripe_price_id,
      price_usd: editedProduct.price_usd,
      sort_order: parseInt(editedProduct.sort_order, 10)
    });

    if (response.status !== 204) {
      window.alert('Error updating product');
      return;
    }

    productUpdated(editedProduct);
  };

  const removeProduct = async (product) => {
    const response = await API.adminDeleteProduct(accessToken, {
      collectionId: product.collection_id,
      productId: product.item_id
    });

    if (response.status !== 204) {
      window.alert('Error removing product');
      return;
    }

    productDeleted(product, product.collection_id);
  };

  // do we want to archive products by not removing but changing status to "archived"?

  const handleProductImageUpload = async (e, collection_id, productId) => {
    const response = await API.adminGetProductImagePresignedUrl(accessToken, {
      // replace selectedCollection with productCollectionId
      collectionId: collection_id,
      productId: productId
    });

    if (response.status !== 200) {
      window.alert('Error getting presignedURL');
      return;
    }
    const data = await response.json();
    let selectedFile = e.target.files[0];
    const s3Response = await fetch(data.presignedUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': 'image/jpeg'
      },
      body: selectedFile
    });
    if (s3Response.status !== 200) {
      window.alert('Failed to upload the image');
      return;
    } else {
      return s3Response.url.split('?')[0];
    }
  };

  // helper functions to track product creation/update/deletion
  const productCreated = (newlyCreatedProduct) => {
    const collection = collections.find((c) => c.id === newlyCreatedProduct.collection_id);
    const updatedCollection = {
      ...collection,
      products: [...collection.products, newlyCreatedProduct]
    };

    console.log('productCreated', updatedCollection);

    // setSelectedCollection(updatedCollection);
    collectionUpdated(updatedCollection);
  };

  const productUpdated = (updatedProduct) => {
    console.log('productUpdated', updatedProduct);
    const collection = collections.find((item) => item.id === updatedProduct.collection_id);
    const tempProductList = collection.products.map((item) => {
      if (item.id === updatedProduct.id) {
        return updatedProduct;
      }
      return item;
    });
    const updatedCollection = { ...collection, products: tempProductList };
    // setSelectedProduct(updatedProduct);
    // setSelectedCollection(updatedCollection);
    collectionUpdated(updatedCollection);
  };

  const productDeleted = (deletedProduct, collection_id) => {
    const collection = collections.find((item) => item.id === collection_id);

    const filteredProducts = collection.products.filter(
      (product) => product.id !== deletedProduct.id
    );
    const updatedCollection = { ...collection, products: filteredProducts };
    //setSelectedCollection(updatedCollection);
    collectionUpdated(updatedCollection);
  };

  useEffect(() => {
    async function getStorefrontData() {
      if (accessToken) {
        const response = await API.adminGetStorefrontInfo(accessToken);
        const storefrontData = await response.json();

        const heroTitle = storefrontData.hero_title;
        const heroSubtitle = storefrontData.hero_subtitle;
        const heroImageUrl = storefrontData.hero_image_url;

        //HACK: adding collectionId into each product. The API should do this for us.
        const collections = storefrontData.collections.map((collection) => {
          const updatedProducts = collection.products.map((product) => ({
            ...product,
            collection_id: collection.id
          }));
          const updatedCollection = { ...collection, products: updatedProducts };
          return updatedCollection;
        });

        setCollections(collections);
        setStorefrontDetails({
          heroTitle: heroTitle,
          heroSubtitle: heroSubtitle,
          heroImageUrl: heroImageUrl
        });
      }
    }
    getStorefrontData();
  }, [accessToken]);

  return (
    <AuthProvider accessToken={accessToken}>
      <div className="storefront-wrapper">
        <EditStorefront
          storefrontDetails={storefrontDetails}
          editStorefrontInfo={editStorefrontInfo}
          handleHeroImageUpload={handleHeroImageUpload}
        />
        <CollectionsTable
          collections={collections}
          setSelectedCollection={setSelectedCollection}
          removeCollection={removeCollection}
          editCollectionInfo={editCollectionInfo}
          createNewCollection={createNewCollection}
          createNewProduct={createNewProduct}
          removeProduct={removeProduct}
          editProductInfo={editProductInfo}
          handleProductImageUpload={handleProductImageUpload}
        />

        <AddNewCollection
          accessToken={accessToken}
          collections={collections}
          setCollections={setCollections}
          createNewCollection={createNewCollection}
        />
        {selectedCollection && (
          <EditCollection
            selectedCollection={selectedCollection}
            editCollectionInfo={editCollectionInfo}
          />
        )}

        {selectedCollection && (
          <AddNewProduct
            createNewProduct={createNewProduct}
            handleProductImageUpload={handleProductImageUpload}
          />
        )}

        {selectedProduct && (
          <EditProduct
            selectedProduct={selectedProduct}
            selectedCollection={selectedCollection}
            editProductInfo={editProductInfo}
            handleProductImageUpload={handleProductImageUpload}
          />
        )}
      </div>
    </AuthProvider>
  );
};

export default StorefrontManagement;
