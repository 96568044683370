import React, { useState } from 'react';

export const StripePriceFormModal = ({ onSubmitNewPrice, onDismiss }) => {
  const [newPriceInput, setNewPriceInput] = useState('');

  const handleNewPriceSubmit = () => {
    const newPrice = parseInt(newPriceInput, 10);
    if (isNaN(newPrice) || newPrice <= 0) {
      window.alert('Please enter a valid price.');
      return;
    }
    onSubmitNewPrice(newPrice);
    dismiss();
  };

  const dismiss = () => {
    setNewPriceInput('');
    onDismiss();
  };

  return (
    <>
      <div className="modal">
        <div className="modal-content">
          <h2>Add New Price</h2>
          <input
            type="number"
            value={newPriceInput}
            onChange={(e) => setNewPriceInput(e.target.value)}
            placeholder="Enter price in USD"
          />
          <div className="button-container">
            <button className="cancel-button" onClick={dismiss}>
              Cancel
            </button>
            <button className="submit-button" onClick={handleNewPriceSubmit}>
              Submit New Price
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
