import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import API from '../API';
import Mixpanel from './Mixpanel';
import CurrencyBundleButtons from './CurrencyBundleButtons';
import CheckoutWithCreditCardButton from './CheckoutWithCreditCardButton';
import CardanoWalletSelector from './CardanoWalletSelector';
import CardanoIcon from './images/svg-icons/cardano-icon.svg';
import { usePrimaryCurrency } from './PrimaryCurrencyContext';
import { useCurrencyBundle } from './CurrencyBundleContext';
import Anvil from './Anvil';
import './PrimaryCurrencyModal.css';

const PrimaryCurrencyModal = ({ showSuccess }) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [isPurchasedSuccessfully] = useState(showSuccess);
  const { selectedBundle } = useCurrencyBundle();
  const [showWalletSelector, setShowWalletSelector] = useState(false);
  const { setPrimaryCurrencyAmount, setIsBalanceFetched } = usePrimaryCurrency();

  useEffect(() => {
    Mixpanel.track('Page Viewed', {
      'Page Type': 'Primary Currency Page'
    });
  }, []);

  useEffect(() => {
    const updateBalance = async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: 'ffclient:play'
        });

        const response = await API.getUnityCurrencyBalance(accessToken);
        if (response.status !== 200) {
          // alert('Error getting Unity balance');
          setIsBalanceFetched(false);
          return;
        }

        const sessionData = await response.json();
        setPrimaryCurrencyAmount(sessionData.currency_balance);
        setIsBalanceFetched(true);
      } catch (error) {
        // alert('Error updating Unity balance');
      }
    };

    if (isAuthenticated) {
      updateBalance();
    }
  }, [isAuthenticated, getAccessTokenSilently, setPrimaryCurrencyAmount, setIsBalanceFetched]);

  if (isAuthenticated && isPurchasedSuccessfully) {
    // Handle the purchased successfully state here, if needed
    // console.log('Stripe Successful Purchase!');
  }

  const createTransaction = async (walletName) => {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: 'ffclient:play'
      });

      await Anvil.wallet.connect(walletName);
      const utxos = await Anvil.wallet.getUtxos();
      const current_wallet = await Anvil.wallet.getBech32ChangeAddress();
      const walletBalance = await Anvil.wallet.getBalanceByPolicies();

      const stripe_product_id = selectedBundle.stripe_product_id;

      const response = await API.createCardanoTransaction(accessToken, {
        stripe_product_id: stripe_product_id,
        send_address: current_wallet,
        utxos: utxos,
        lovelace_balance: walletBalance.cardano.lovelace
      });

      if (response.status === 402) {
        const responseError = await response.json();
        console.log(responseError.error);
        alert('Error: ' + responseError.error);
        return;
      }

      if (response.status !== 200) {
        alert('Error creating transaction');
        return;
      }

      const responseData = await response.json();
      return responseData;
    } catch (error) {
      alert('Error building transaction');
      console.log(error);
    }
  };

  const signTransaction = async (transaction, stripe_product_id, walletName) => {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: 'ffclient:play'
      });

      await Anvil.wallet.connect(walletName);
      const sign = await Anvil.walletExt.signAndSubmit({
        transaction: transaction,
        partialSign: true
      });

      // Queue up the transaction intent
      await API.submitVirtualCurrencyTransaction(accessToken, {
        submitted_tx: sign.submittedTx,
        stripe_product_id: stripe_product_id
      });

      // Once transaction hash is created, compare queued up intent
      const transactionResponse = await API.postVirtualCurrencyTransaction(accessToken, {
        submitted_tx: sign.submittedTx,
        transaction_hash: sign.transactionHash
      });
      if (transactionResponse.status !== 200) {
        alert('Error with signed currency transaction');
        return;
      }

      const transactionResponseData = await transactionResponse.json();
      // Update the virtual currency balance after transaction completes
      setPrimaryCurrencyAmount(transactionResponseData.currency_balance);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectWallet = async (walletName) => {
    if (selectedBundle == null) {
      alert('Please select a bundle before checking out.');
      return;
    }

    // Wait for the transaction to be created before signing it
    try {
      const transactionResponse = await createTransaction(walletName); // Wait until the transaction is created
      if (transactionResponse) {
        await signTransaction(
          transactionResponse.transaction,
          transactionResponse.stripe_product_id,
          walletName
        ); // Then sign the transaction
      } else {
        console.error('Failed to create transaction');
      }
    } catch (error) {
      console.error('Error in handling wallet selection:', error);
      alert('An error occurred during transaction creation or signing.');
    }
  };

  return (
    <div className="primary-currency-modal">
      <h1 className="primary-currency-modal-header">Add Fest Coin</h1>
      <div className="currency-tile-container">
        <CurrencyBundleButtons />
      </div>
      <div className="payment-method-container">
        <CheckoutWithCreditCardButton />
      </div>
      <button
        className="checkout-with-credit-card-btn"
        onClick={() => setShowWalletSelector(!showWalletSelector)}
      >
        <img src={CardanoIcon} alt="Checkout Icon" />
        Pay with Cardano
      </button>
      {showWalletSelector && (
        <>
          <div className="select-wallet-header">
            <h2 className="primary-currency-modal-header-03">Select a Wallet</h2>
          </div>
          <div className="wallet-selector-container">
            <CardanoWalletSelector onSelectWallet={handleSelectWallet} />
          </div>
        </>
      )}
    </div>
  );
};

export default PrimaryCurrencyModal;
