import * as React from 'react';
import { useLocation } from 'react-router-dom';
import Page from '../components/Page/Page';
import EditUsername from '../components/EditUsername/EditUsername';

const SetUsernamePage: React.FC = () => {
  const location = useLocation();
  const [error, setError] = React.useState<string | null>(null);

  React.useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const errorParam = searchParams.get('error');
    if (errorParam) {
      setError(errorParam);
    }
  }, [location.search]);

  const handleUpdateUsername = (username: string) => {
    try {
      const searchParams = new URLSearchParams(location.search);
      const state = searchParams.get('state');

      if (state) {
        const form = document.createElement('form');
        form.method = 'POST';
        form.action = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/continue?state=${state}`;

        const usernameInput = document.createElement('input');
        usernameInput.type = 'hidden';
        usernameInput.name = 'username';
        usernameInput.value = username;
        form.appendChild(usernameInput);

        document.body.appendChild(form);
        form.submit();
      } else {
        setError('Authentication state is missing. Please try logging in again.');
      }
    } catch (error) {
      console.error('Error updating username:', error);
      setError(error.message || 'An unknown error occurred. Please try again.');
    }
  };

  return (
    <Page wallets={[]} setWallets={() => {}}>
      <h2 className="ff-header">Set Username</h2>
      {error && <p className="error-message">{error}</p>}
      <EditUsername onUpdateUsername={handleUpdateUsername} />
    </Page>
  );
};

export default SetUsernamePage;
