import React from 'react';
import { createContext, useContext, useState } from 'react';
const Cart = createContext();

const CartContext = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);
  return <Cart.Provider value={{ cartItems, setCartItems }}>{children}</Cart.Provider>;
};

export const CartState = () => {
  return useContext(Cart);
};
export default CartContext;
