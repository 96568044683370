import { useState, useEffect } from 'react';
// import './table.css';

export const EditableCell = ({ getValue, row, column, table, inputType }) => {
  console.log(
    'editableCell unstableTableData',
    table.options.meta.unstableTableData,
    table.options.meta.unstableTableData[row.index],
    row.index,
    column.id
  );
  const initialValue = table.options.meta.unstableTableData[row.index][column.id]; //getValue();
  const columnMeta = column.columnDef.meta;
  const tableMeta = table.options.meta;
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const onBlur = () => {
    tableMeta?.updateData(row.index, column.id, value);
  };

  const onSelectChange = (e) => {
    setValue(e.target.value);
    tableMeta?.updateData(row.index, column.id, e.target.value);
  };

  return inputType === 'select' ? (
    <select onChange={onSelectChange} value={initialValue}>
      {columnMeta?.options?.map((option) => (
        <option value={option.value}>{option.label}</option>
      ))}
    </select>
  ) : (
    <input
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={onBlur}
      type={columnMeta?.type || 'text'}
    />
  );
};
