import * as React from 'react';
import { useState, useEffect } from 'react';

interface EditDeeplinkProps {
  /**
   * Launcher Version
   */
  launcherVersion?: string;
  /**
   * Callback handler when "Update" is clicked
   */
  updateDeeplinkInfo?: (info: DeeplinkInfo) => void;
}

interface DeeplinkInfo {
  launcherVersion: string;
}

/**
 * Primary UI component for displaying a product
 */
const EditDeeplink: React.FC<EditDeeplinkProps> = ({
  launcherVersion = '',
  updateDeeplinkInfo = () => {}
}) => {
  const [launcherVersion2, setLauncherVersion2] = useState(launcherVersion);
  useEffect(() => {
    setLauncherVersion2(launcherVersion);
  }, [launcherVersion]);
  return (
    <div className="flex flex-col gap-3 border-2 rounded-3xl p-6 bg-[#1f1f1f]">
      <div className="flex flex-wrap gap-3 items-center">
        <label className="text-right text-white" htmlFor="inputlauncherVersion">
          Launcher Version:
        </label>
        <input
          id="inputlauncherVersion"
          className="flex grow border-2 text-black"
          value={launcherVersion2}
          type="text"
          onChange={(e) => setLauncherVersion2(e.target.value)}
        />
      </div>
      <div className="flex gap-3 justify-center">
        <button
          className="bg-[#5239ea] h-12 border-2 rounded-xl p-1 min-w-[150px] text-white"
          onClick={() =>
            updateDeeplinkInfo({
              launcherVersion: launcherVersion2
            })
          }
        >
          Update
        </button>
      </div>
    </div>
  );
};

export default EditDeeplink;
