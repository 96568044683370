import React, { useEffect } from 'react';
import SystemRequirementsImage from './images/system_requirements.png';

const WelcomeToDesktopBeta = ({ accessToken, deeplink, isAppleSilicon }) => {
  // Re-render if deeplink is updated
  useEffect(() => {}, [deeplink]);

  async function enterWorld() {
    window.location.href = deeplink;
  }
  return (
    <div className="flex flex-col gap-2 pt-3 container main-container ff-center px-5 font-['Poppins']">
      <h2 className="font-bold text-xl text-neutral-50">Welcome to Future Fest Desktop Beta!</h2>
      <div className="flex gap-2 rounded-3xl p-8 w-[645px]" style={{ backgroundColor: '#FDFDFD' }}>
        <img className="self-end mb-[-32px]" src="/purple_bot.png " alt="Futurebot" />
        <div className="flex flex-col gap-2">
          <h2 className="font-bold text-xl text-neutralColor-100">
            Is your launcher stuck updating?
          </h2>
          <div className="text-neutralColor-60 text-base">
            Make sure you are on launcher version 3.0 to access the world. If you've joined our past
            events, you will still need to update the launcher.
          </div>
          <div className="flex gap-2">
            <img src="/old_launcher.png" alt="Incorrect launcher" />
            <img src="/new_launcher.png" alt="Correct launcher" />
          </div>
        </div>
      </div>
      <img src={SystemRequirementsImage} alt="System requirements" />
      <button className="ff-button" onClick={enterWorld}>
        Enter World
      </button>
      <br />
      <p style={{ fontSize: 'smaller' }} className="connect-wallet-subtitle">
        Launcher still didn't download?
      </p>
      <span style={{ fontSize: 'smaller' }}>
        <a
          href={
            isAppleSilicon
              ? 'https://d2xen1wf0yg342.cloudfront.net/FutureFestLauncher_Beta.dmg'
              : 'https://d2xen1wf0yg342.cloudfront.net/FutureFestLauncherSetup.exe'
          }
        >
          Download Installer
        </a>{' '}
        or{' '}
        <a href="https://www.futurefest.io/support" target="_blank" rel="noopener noreferrer">
          Click here for help
        </a>
      </span>
    </div>
  );
};

export default WelcomeToDesktopBeta;
