import React, { useEffect, useState } from 'react';
import './Storefront.css';

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  useReactTable
} from '@tanstack/react-table';

import ProductsTable from './ProductsTable';
import { ColumnHeaderCell } from './ColumnHeaderCell';
import { AdminButton } from './AdminButton';
import { EditableCell } from './EditableCell';
import { SearchBar } from './SearchBar';
// import { DragAndDrop } from './DragAndDrop';
import { StatusSelector } from './StatusSelector';
import { RowActions } from './RowActions';
import { EditTableRow } from './EditTableRow';
import { TableFooter } from './TableFooter';
import SortIcon from '../images/svg-icons/sort-icon.svg';

const CollectionsTable = ({
  collections,
  setSelectedCollection,
  removeCollection,
  editCollectionInfo,
  createNewCollection,
  createNewProduct,
  handleProductImageUpload,
  editProductInfo,
  removeProduct
}) => {
  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.display({
      header: () => (
        <div className="admin-header-button">
          <AdminButton
            className="add-collection-button"
            buttonText="Add Collection"
            handleClick={() => createNewCollection('Default Collection')}
          />
        </div>
      ),
      id: 'actions',
      cell: (props) => (
        <RowActions
          row={props.row}
          table={props.table}
          tableData={unstableCollectionData}
          editInfo={editCollectionInfo}
          removeInfo={removeCollection}
          showExpander={true}
        />
      )
    }),
    columnHelper.accessor('name', {
      header: (props) => (
        <ColumnHeaderCell
          headerText="Collection Name"
          handleSort={props.column.getToggleSortingHandler()}
        />
      ),
      enableColumnFilter: true,
      cell: (props) => {
        return (
          <>
            {props.row.getIsSelected() ? (
              // <input value={props.getValue()} />
              <EditableCell
                inputType={'text'}
                getValue={props.getValue}
                row={props.row}
                column={props.column}
                table={props.table}
              />
            ) : (
              <span className="collection-name-cell">{props.getValue()}</span>
            )}
          </>
        );
      }
    }),
    columnHelper.accessor('products', {
      header: (props) => (
        <ColumnHeaderCell
          headerText="# of Items"
          handleSort={props.column.getToggleSortingHandler()}
        />
      ),
      cell: (info) => info.getValue().length
    }),
    columnHelper.accessor('status', {
      header: (props) => (
        <ColumnHeaderCell headerText="Status" handleSort={props.column.getToggleSortingHandler()} />
      ),
      cell: (props) => {
        const status = props.getValue();
        return (
          <StatusSelector
            row={props.row}
            column={props.column}
            table={props.table}
            status={status}
          />
        );
      },
      meta: {
        options: [
          { value: 'staged', label: 'Staged' },
          { value: 'published', label: 'Published' }
        ]
      }
    }),
    columnHelper.accessor('sort_order', {
      header: () => (
        <div className="column-header-cell">
          <span>SORT ORDER</span>
          <img className="column-sort-icon" src={SortIcon} alt="Sort" />
        </div>
      ),
      cell: (props) => {
        return (
          <>
            {props.row.getIsSelected() ? (
              <EditableCell
                inputType={'text'}
                getValue={props.getValue}
                row={props.row}
                column={props.column}
                table={props.table}
              />
            ) : (
              <span className="collection-name-cell">{props.getValue()}</span>
            )}
          </>
        );
      }
    })
  ];

  // const [data] = React.useState(() => [...defaultData]);
  // const rerender = React.useReducer(() => ({}), {})[1];
  const [unstableCollectionData, setUnstableCollectionData] = useState(collections);
  const [rowSelection, setRowSelection] = useState({});
  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);

  const [searchInput, setSearchInput] = useState('');
  const [filtered, setFiltered] = useState(false);
  const [globalFilter, setGlobalFilter] = useState('');

  const sortCollections = (collections) => {
    return collections.slice().sort((a, b) => a['sort_order'] - b['sort_order']);
  };

  useEffect(() => {
    const sortedCollections = sortCollections(collections);
    setUnstableCollectionData(sortedCollections);
  }, [collections]);

  const table = useReactTable({
    data: unstableCollectionData,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getRowCanExpand: () => true,
    enableExpanding: true,
    // debugTable: true,
    // debugRows: true,
    // debugAll: true,
    state: {
      rowSelection,
      sorting,
      columnFilters,
      globalFilter
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    enableSorting: true,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    enableColumnFilters: true,
    enableGlobalFilters: true,
    enableFilters: true,
    onSortingChange: setSorting,
    meta: {
      updateData: (rowIndex, columnId, value) => {
        setUnstableCollectionData((old) =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...old[rowIndex],
                [columnId]: value
              };
            }
            return row;
          })
        );
      },
      unstableTableData: unstableCollectionData
    }
  });

  const rowIsInEditState = false;

  return (
    <>
      <SearchBar
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        filtered={filtered}
        setFiltered={setFiltered}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
        filterFunction={table.getGlobalFilterFn()}
      />
      <table className="admin-table">
        <thead>
          {table.getHeaderGroups().map((headerGroup, index) => {
            return (
              <tr className="admin-table-header" key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th className="admin-table-header" key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                ))}
              </tr>
            );
          })}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row, index) => {
            const expandedRowStyle = row.getIsExpanded()
              ? 'expanded-admin-table-row'
              : 'admin-table-row';

            // const expandedCellStyle = row.getIsExpanded()
            //   ? 'expanded-admin-table-cell'
            //   : 'admin-table-cell';

            return (
              <>
                {rowIsInEditState ? (
                  <EditTableRow row={row} />
                ) : (
                  <tr className={expandedRowStyle} key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <td className="admin-table-cell" key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                )}
                {row.getIsExpanded() && (
                  <tr className="admin-table-row">
                    <td colSpan="6">
                      <div className="table-subcomponent-wrapper">
                        <ProductsTable
                          selectedCollection={unstableCollectionData[index]}
                          productCollectionId={row.original.id}
                          // selectedProduct={selectedProduct}
                          // setSelectedProduct={setSelectedProduct}
                          // productUpdated={productUpdated}
                          createNewProduct={createNewProduct}
                          removeProduct={removeProduct}
                          editProductInfo={editProductInfo}
                          handleProductImageUpload={handleProductImageUpload}
                        />
                      </div>
                    </td>
                  </tr>
                )}
              </>
            );
          })}
        </tbody>
        <TableFooter table={table} />
      </table>
    </>
  );
};

export default CollectionsTable;
