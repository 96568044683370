import React, { useEffect, useState } from 'react';
import API from '../../API';
import Mixpanel from '../Mixpanel';
import EditDeeplink from '../EditDeeplink/EditDeeplink';

const EditDeeplinkPage = ({ accessToken }) => {
  const [deeplinkInfo, setDeeplinkInfo] = useState({});

  useEffect(() => {
    Mixpanel.track('Page Viewed', {
      'Page Type': 'Edit Deeplink'
    });
  }, []);

  useEffect(() => {
    async function getDeeplink() {
      const response = await API.getDeeplink();
      if (response.status !== 200) {
        window.alert(`Error getting deeplink info.`);
        return;
      }
      const data = await response.json();
      setDeeplinkInfo(data);
    }
    getDeeplink();
  }, []);
  async function updateDeeplinkInfo(info) {
    if (accessToken) {
      const response = await API.adminUpdateDeeplink(accessToken, info);
      if (response.status !== 204) {
        window.alert('Error updating Deeplink info');
        return;
      }
      window.alert('Deeplink Info updated successfully!');
    }
  }

  return (
    <EditDeeplink
      gameDownloadUrl={deeplinkInfo.gameDownloadUrl}
      launcherVersion={deeplinkInfo.launcherVersion}
      updateDeeplinkInfo={updateDeeplinkInfo}
    />
  );
};

export default EditDeeplinkPage;
