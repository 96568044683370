import React, { useEffect } from 'react';
import Modal from 'react-modal';
import FFIcon from './images/ff-icon.png';

const ClientSelectionModal = ({
  accessToken,
  showClientSelectionModal,
  setShowClientSelectionModal,
  callback,
  furioosCallback,
  deeplink,
  isAppleSilicon,
  showBrowserOption
}) => {
  // Re-render if deeplink is updated
  useEffect(() => {}, [deeplink]);

  async function useDesktopClient() {
    //window.location.href = deeplink
    setShowClientSelectionModal(false);
    callback();
  }
  async function playInBrowser() {
    setShowClientSelectionModal(false);
    furioosCallback();
  }

  return (
    <Modal
      isOpen={showClientSelectionModal}
      parentSelector={() => document.querySelector('.enter-world-container')}
      className="RegionSelectionModal__Content ff-modal-content ff-center"
      overlayClassName="RegionSelectionModal__Overlay"
      onRequestClose={() => setShowClientSelectionModal(false)}
      shouldCloseOnOverlayClick={false}
    >
      <img style={{ objectFit: 'contain' }} src={FFIcon} alt="Future Fest Icon" />
      <h2 className="connect-wallet-title">Welcome to Future Fest</h2>
      <p className="connect-wallet-subtitle">
        Your experience is now loading. Get ready! <br />{' '}
        {isAppleSilicon && <strong>(Experimental)</strong>}
      </p>
      <button className="ff-button" onClick={useDesktopClient}>
        Use Desktop Client
      </button>
      {showBrowserOption && (
        <>
          <p className="connect-wallet-subtitle">------- or -------</p>
          <button
            onClick={playInBrowser}
            style={{
              border: '0px',
              backgroundColor: 'transparent',
              fontFamily: 'Poppins',
              fontStyle: 'normal',
              fontWeight: '600',
              fontSize: '16px',
              lineHeight: '24px',
              textAlign: 'center',
              letterSpacing: '0.2px',
              color: '#5239EA'
            }}
          >
            Play in browser
            <br /> (recommended for laptops)
          </button>
        </>
      )}
    </Modal>
  );
};

export default ClientSelectionModal;
