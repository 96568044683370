import React, { useRef } from 'react';
import './Storefront.css';
import EditIcon from '../images/svg-icons/edit-icon.svg';

export const ProductThumbnail = ({
  imageSourceUrl,
  handleProductImageUpload,
  productCollectionId,
  productId,
  productImageUpdated
}) => {
  // onClick opens file uploader and changes the row to editable?
  // selecting a file closes file uploader
  // update product when file is chosen
  const fileInputRef = useRef(null);

  const handleThumbnailClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (e) => {
    const url = await handleProductImageUpload(e, productCollectionId, productId);
    productImageUpdated({ url, productId });
  };

  return (
    <div className="product-thumbnail-container" onClick={handleThumbnailClick}>
      {imageSourceUrl ? (
        <img className="product-thumbnail-image" src={imageSourceUrl} alt="Product" />
      ) : (
        <div className="add-image-placeholder">Add Image</div>
      )}
      <div className="upload-icon-container">
        <img src={EditIcon} alt="Edit" />
      </div>
      <input
        id={`productImage_${productId}`}
        type="file"
        accept="image/*"
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
    </div>
  );
};
