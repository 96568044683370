import React, { useEffect, useState } from 'react';
import { useAuth } from '../AuthContext';
import API from '../../API';
import './Storefront.css';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable
} from '@tanstack/react-table';
import { AdminButton } from './AdminButton';
import { ProductThumbnail } from './ProductThumbnail';
import { EditableCell } from './EditableCell';
import SortIcon from '../images/svg-icons/sort-icon.svg';
import { TableFooter } from './TableFooter';
import { StatusSelector } from './StatusSelector';
// import { DragAndDrop } from './DragAndDrop';
import { RowActions } from './RowActions';
import { StripePriceFormModal } from './StripePriceFormModal';
import { StripePricesModal } from './StripePricesModal';
const ProductsTable = ({
  productUpdated,
  selectedCollection,
  createNewProduct,
  removeProduct,
  editProductInfo,
  productCollectionId,
  handleProductImageUpload
}) => {
  const productsInCollection = selectedCollection.products;
  const [rowSelection, setRowSelection] = React.useState({});
  const [unstableProductData, setUnstableProductData] = useState(productsInCollection);
  const { accessToken } = useAuth();
  const [currentRow, setCurrentRow] = useState(null);
  const [stripePrices, setStripePrices] = useState([]);
  const [showStripePricesModal, setShowStripePricesModal] = useState(false);
  const [showNewPriceForm, setShowNewPriceForm] = useState(false);

  useEffect(() => {
    console.log('ProductsTable reloaded', selectedCollection);
    // Apply sorting when the component mounts or selectedCollection changes
    const sortedProducts = sortProducts(selectedCollection.products);
    setUnstableProductData(sortedProducts);
  }, [selectedCollection]);

  const sortProducts = (products) => {
    return products.slice().sort((a, b) => a['sort_order'] - b['sort_order']);
  };

  const productImageUpdated = ({ productId, url }) => {
    const updatedUnstableProductData = unstableProductData.map((product) => {
      if (product.item_id === productId) {
        product.image_url = url;
      }
      return product;
    });
    setUnstableProductData(updatedUnstableProductData);
  };

  const handlePriceConfirmation = (selectedPriceId, selectedPriceUsd) => {
    const { stripe_product_id } = currentRow.original;
    // Assuming you have the product ID available or can derive it
    const updatedData = unstableProductData.map((product) => {
      if (product.stripe_product_id === stripe_product_id) {
        product.stripe_price_id = selectedPriceId;
        product.price_usd = selectedPriceUsd;
      }
      return product;
    });
    setUnstableProductData(updatedData);
    setShowStripePricesModal(false);
  };

  const handleShowPriceForm = (row) => {
    setCurrentRow(row);
    setShowNewPriceForm(true);
  };

  const handleCloseStripeModal = () => {
    setStripePrices([]);
    setShowStripePricesModal(false);
  };

  const handleAddProductClick = async (row) => {
    const { id, name, image_url, description } = row.original;

    try {
      const response = await API.adminCreateStripeProduct(accessToken, {
        id: id,
        name: name,
        image_url: image_url,
        description: description
      });

      if (response.status !== 200) {
        window.alert('Error creating product');
        return;
      }

      const newProduct = await response.json();

      // Update state to include the new product
      const updatedUnstableProductData = unstableProductData.map((product) => {
        if (product.item_id === newProduct.item_id) {
          product.stripe_product_id = newProduct.stripe_product_id;
        }
        return product;
      });

      setUnstableProductData(updatedUnstableProductData);
    } catch (error) {
      console.error('Error creating product:', error);
      // Handle any errors, such as network issues
    }
  };

  const handlePriceSubmit = async (price) => {
    if (!currentRow) return;

    const priceUSD = parseInt(price, 10);
    if (isNaN(priceUSD) || priceUSD < 0) {
      window.alert('Invalid price');
      return;
    }

    try {
      const { id, name, image_url, stripe_product_id } = currentRow.original;

      const response = await API.adminCreateStripePrice(accessToken, {
        id: id,
        name: name,
        image_url: image_url,
        stripe_product_id: stripe_product_id,
        price_usd: parseInt(price, 10)
      });

      if (response.status !== 200) {
        window.alert('Error creating product');
        return;
      }

      const newProduct = await response.json();

      // Update state to include the new product
      const updatedUnstableProductData = unstableProductData.map((product) => {
        if (product.item_id === newProduct.item_id) {
          product.stripe_price_id = newProduct.stripe_price_id;
          product.price_usd = newProduct.price_usd;
        }
        return product;
      });

      setUnstableProductData(updatedUnstableProductData);

      // Close modal after successful operation
      handleCloseStripeModal();
    } catch (error) {
      console.error('Error:', error);
      // Handle error
    }
  };

  const handleGetPrices = async (row) => {
    const { stripe_product_id, stripe_price_id } = row.original;

    if (!stripe_product_id) {
      window.alert('Missing Stripe Product');
      return;
    }

    if (!stripe_price_id) {
      setCurrentRow(row);
      setShowStripePricesModal(true);
      return;
    }

    try {
      const response = await API.adminGetStripePrices(accessToken, {
        productId: stripe_product_id
      });

      if (response.status === 200) {
        const data = await response.json();
        setCurrentRow(row);
        setStripePrices(data.prices); // Assuming the API response structure
        setShowStripePricesModal(true);
      } else {
        window.alert('Error getting prices');
      }
    } catch (error) {
      console.error('Error:', error);
      window.alert('Error getting prices');
    }
  };

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.display({
      id: 'actions',
      cell: (props) => {
        return (
          <RowActions
            row={props.row}
            table={props.table}
            tableData={unstableProductData}
            editInfo={editProductInfo}
            removeInfo={removeProduct}
            showExpander={false}
          />
        );
      }
    }),
    columnHelper.accessor('image_url', {
      id: 'image-upload',
      header: () => (
        <div className="column-header-cell">
          <span>IMAGE</span>
        </div>
      ),
      cell: (props) => {
        return (
          <ProductThumbnail
            productCollectionId={productCollectionId}
            handleProductImageUpload={handleProductImageUpload}
            imageSourceUrl={props.getValue()}
            productId={props.row.original.item_id}
            productImageUpdated={productImageUpdated}
          />
        );
      }
    }),
    columnHelper.accessor('name', {
      header: () => (
        <div className="column-header-cell">
          <span>ITEM NAME</span>
        </div>
      ),
      cell: (props) => {
        return (
          <>
            {props.row.getIsSelected() ? (
              <EditableCell
                inputType={'text'}
                getValue={props.getValue}
                row={props.row}
                column={props.column}
                table={props.table}
              />
            ) : (
              <span className="product-name-cell">{props.getValue()}</span>
            )}
          </>
        );
      }
    }),
    columnHelper.accessor('id', {
      header: () => (
        <div className="column-header-cell">
          <span>ID</span>
        </div>
      ),
      cell: (info) => {
        return info.getValue();
      }
    }),
    columnHelper.accessor('status', {
      header: () => (
        <div className="column-header-cell">
          <span>STATUS</span>
        </div>
      ),
      cell: (props) => {
        const status = props.getValue();
        return (
          <StatusSelector
            row={props.row}
            column={props.column}
            table={props.table}
            status={status}
          />
        );
      },
      meta: {
        options: [
          { value: 'staged', label: 'Staged' },
          { value: 'published', label: 'Published' }
        ]
      }
    }),
    columnHelper.accessor('description', {
      header: () => (
        <div className="column-header-cell">
          <span>DESCRIPTION</span>
        </div>
      ),
      cell: (props) => {
        return (
          <>
            {props.row.getIsSelected() ? (
              <EditableCell
                inputType={'text'}
                getValue={props.getValue}
                row={props.row}
                column={props.column}
                table={props.table}
              />
            ) : (
              <span className="product-name-cell">{props.getValue()}</span>
            )}
          </>
        );
      }
    }),
    columnHelper.accessor('stripe_product_id', {
      header: () => (
        <div className="column-header-cell">
          <span>PRODUCT ID</span>
        </div>
      ),
      cell: (info) => {
        const stripeProductId = info.getValue();
        return stripeProductId ? (
          <span>{stripeProductId}</span>
        ) : (
          <button
            className="admin-stripe-add-button"
            onClick={() => handleAddProductClick(info.row)}
          >
            Add Product
          </button>
        );
      }
    }),
    columnHelper.accessor('stripe_price_id', {
      header: () => (
        <div className="column-header-cell">
          <span>PRICE ID</span>
        </div>
      ),
      cell: (info) => {
        const stripePriceId = info.getValue();

        if (info.row.getIsSelected()) {
          // Show buttons when the row is in edit mode
          return (
            <div>
              {stripePriceId ? (
                <button
                  className="admin-stripe-add-button"
                  onClick={() => handleGetPrices(info.row)}
                >
                  {stripePriceId}
                </button>
              ) : (
                <button
                  className="admin-stripe-add-button"
                  onClick={() => handleShowPriceForm(info.row)}
                >
                  Add Price
                </button>
              )}
            </div>
          );
        } else {
          // Show only the stripePriceId when not in edit mode
          return <span>{stripePriceId}</span>;
        }
      }
    }),
    columnHelper.accessor('price_usd', {
      header: () => (
        <div className="column-header-cell">
          <span>USD PRICE</span>
        </div>
      ),

      cell: (info) => {
        return info.getValue();
      }
    }),
    columnHelper.accessor('price', {
      header: () => (
        <div className="column-header-cell">
          <span>ADA PRICE</span>
        </div>
      ),
      cell: (props) => {
        return (
          <>
            {props.row.getIsSelected() ? (
              <EditableCell
                inputType={'text'}
                getValue={props.getValue}
                row={props.row}
                column={props.column}
                table={props.table}
              />
            ) : (
              <span className="product-name-cell">{props.getValue()}ADA</span>
            )}
          </>
        );
      }
    }),
    columnHelper.accessor('sort_order', {
      header: () => (
        <div className="column-header-cell">
          <span>SORT ORDER</span>
          <img className="column-sort-icon" src={SortIcon} alt="Sort" />
        </div>
      ),
      cell: (props) => {
        return (
          <>
            {props.row.getIsSelected() ? (
              <EditableCell
                inputType={'text'}
                getValue={props.getValue}
                row={props.row}
                column={props.column}
                table={props.table}
              />
            ) : (
              <span className="product-name-cell">{props.getValue()}</span>
            )}
          </>
        );
      }
    })
  ];

  const table = useReactTable({
    data: unstableProductData,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getRowCanExpand: () => true,
    enableExpanding: true,
    // debugTable: true,
    // debugRows: true,
    // debugAll: true,
    state: {
      rowSelection
    },
    meta: {
      updateData: (rowIndex, columnId, value) => {
        setUnstableProductData((old) =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...old[rowIndex],
                [columnId]: value
              };
            }
            return row;
          })
        );
      },
      unstableTableData: unstableProductData
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection
  });

  const EditProductRow = ({ row }) => (
    <tr className="admin-table-row" key={row.id}>
      {row.getVisibleCells().map((cell) => (
        <td className="admin-table-cell products-table-cell" key={cell.id}>
          <input value={cell.getValue()} onChange></input>
        </td>
      ))}
    </tr>
  );

  return (
    <>
      {showNewPriceForm && (
        <StripePriceFormModal
          onSubmitNewPrice={handlePriceSubmit}
          onDismiss={() => setShowNewPriceForm(false)}
        />
      )}
      {showStripePricesModal && (
        <StripePricesModal
          prices={stripePrices}
          onClose={handleCloseStripeModal}
          onConfirm={handlePriceConfirmation}
          onPlus={() => setShowNewPriceForm(true)}
        />
      )}
      {/* <pre>{JSON.stringify(unstableCollectionData, null, 2)}</pre> */}
      <table className="products-table">
        <thead className="products-table-header">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr className="admin-table-header" key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th className="admin-table-header" key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row, index) => (
            <>
              {false ? (
                <EditProductRow row={row} />
              ) : (
                <tr className="admin-table-row" key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td className="admin-table-cell" key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              )}
            </>
          ))}
          <tr className="admin-table-row">
            <td className="admin-button-cell" colSpan="3">
              <AdminButton
                className="add-shop-item-button"
                buttonText="Add Shop Item"
                handleClick={() => {
                  return createNewProduct({
                    productName: 'Default Product',
                    productCategory: 'Default Category',
                    productPrice: 999999,
                    collection_id: productCollectionId
                    // productCollectionId: productCollectionId
                  });
                }}
              />
            </td>
          </tr>
        </tbody>
        <TableFooter table={table} />
      </table>
    </>
  );
};

export default ProductsTable;
