import React from 'react';
import './Storefront.css';

export const ColumnHeaderCell = ({ headerText, handleSort }) => {
  return (
    <div className="column-header-cell">
      <span>{headerText.toUpperCase()}</span>
    </div>
  );
};
