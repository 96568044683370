import * as React from 'react';
import { useNavigate } from 'react-router-dom';

interface HeroProps {}

/**
 * Primary UI component for displaying a hero
 */
const Hero: React.FC<HeroProps> = () => {
  const navigate = useNavigate();
  const handleClickScroll = () => {
    const element = document.getElementById('Bear-Market-Brawl');
    console.log(element);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <div
      className="pt-20 md:py-0 px-5 items-center justify-between bg-[#FFDDDD] font-['Poppins'] rounded-b-[40px] text-primaryColor-darkPurple"
      style={{
        background:
          'linear-gradient(102deg, #FFDDDD 0%, #FEE3E7 0%, #FBF2FF 33%, #F9EEFF 67%, #FFF8FD 100%)'
      }}
    >
      <div className="container mx-auto flex flex-col gap-20 md:flex-row">
        <div className="flex flex-col gap-6 my-auto">
          <h1 className="text-6xl font-black">Level up your look!</h1>
          <h2 className="text-4xl font-bold">Futurebot cosmetics from your favorite brands</h2>
          {/* <p className="text-neutralColor-80 text-base font-medium">
          Welcome to the Future Fest Store. Unleash your inner creativity and individuality by
          adorning your favorite digital assets with our vast selection of high-quality skins.
        </p> */}
          <div className="flex gap-5">
            <button
              className="border-2 rounded-full px-7 py-2 border-primaryColor-lightPurple bg-primaryColor-lightPurple text-neutralColor-0"
              onClick={handleClickScroll}
            >
              Shop now
            </button>
            <button
              className="flex items-center gap-2 border-2 rounded-full px-7 py-2 border-primaryColor-lightPurple text-primaryColor-lightPurple text-base"
              onClick={() => {
                navigate('/');
              }}
            >
              Try now for free
            </button>
          </div>
        </div>
        <img className="relative h-[695px]" src="bmb.png" alt="Bear Market Brawl" />
      </div>
    </div>
  );
};

export default Hero;
