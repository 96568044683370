import * as React from 'react';
import { useState, useEffect } from 'react';

interface EditUsernameProps {
  initialUsername?: string;
  onUpdateUsername: (username: string) => void;
}

const EditUsername: React.FC<EditUsernameProps> = ({ initialUsername = '', onUpdateUsername }) => {
  const [username, setUsername] = useState(initialUsername);

  useEffect(() => {
    setUsername(initialUsername || '');
  }, [initialUsername]);

  const handleUpdate = () => {
    onUpdateUsername(username);
  };

  return (
    <div className="flex flex-col gap-3 border-2 rounded-3xl p-6 bg-[#1f1f1f]">
      <div className="flex flex-wrap gap-3 items-center">
        <label className="text-right text-white" htmlFor="inputUsername">
          Username:
        </label>
        <input
          id="inputUsername"
          className="flex grow border-2 text-black"
          value={username}
          type="text"
          onChange={(e) => setUsername(e.target.value)}
        />
      </div>
      <div className="flex gap-3 justify-center">
        <button
          className="bg-[#5239ea] h-12 border-2 rounded-xl p-1 min-w-[150px] text-white"
          onClick={handleUpdate}
        >
          Update
        </button>
      </div>
    </div>
  );
};

export default EditUsername;
