import React, { useState, useEffect } from 'react';
import './Storefront.css';

const EditCollection = ({ selectedCollection, editCollectionInfo }) => {
  const [collectionName, setCollectionName] = useState(null);

  useEffect(() => {
    setCollectionName(selectedCollection.name);
  }, [selectedCollection]);

  return (
    <div className="info-container">
      <h3>Edit Collection</h3>
      <div className="input-container">
        <label htmlFor="collectionName">Collection Name:</label>
        <input
          id="collectionName"
          type="text"
          value={collectionName ? collectionName : selectedCollection.name}
          onChange={(e) => setCollectionName(e.target.value)}
        ></input>
      </div>

      <div className="actions-container">
        <button className="ff-button" onClick={() => editCollectionInfo(collectionName)}>
          Update
        </button>
      </div>
    </div>
  );
};

export default EditCollection;
