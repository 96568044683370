import React, { useState } from 'react';
import './ConnectWalletButton.css';
import Modal from 'react-modal';
import ConnectWalletAnvil from './ConnectWalletAnvil';

const ConnectWalletButton = ({ className, wallets, setWallets }) => {
  const [showAddWalletModal, setShowAddWalletModal] = useState(false);
  return (
    <div>
      <button className={`ff-button ${className}`} onClick={() => setShowAddWalletModal(true)}>
        Connect Wallet
      </button>
      <Modal
        isOpen={showAddWalletModal}
        parentSelector={() => document.querySelector('.App')}
        className="AddWalletModal__Content ff-modal-content"
        overlayClassName="AddWalletModal__Overlay ff-overlay"
        onRequestClose={() => setShowAddWalletModal(false)}
        shouldCloseOnOverlayClick={true}
      >
        <ConnectWalletAnvil
          wallets={wallets}
          setWallets={setWallets}
          callback={(wallets) => {
            setShowAddWalletModal(false);
          }}
        />
      </Modal>
    </div>
  );
};

export default ConnectWalletButton;
