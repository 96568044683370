import React, { useState } from 'react';
import './EditEvent.css';
import API from '../../API';
import { Link } from 'react-router-dom';

const EditEvent = ({ event, accessToken }) => {
  const futurebot_policy_id = '2b43a53d30347c1983d632e0437e2dec5a5c00ee3007627f1f0b1d30';
  const deluxebot_policy_id = 'b65ce524203b7a7d48b55ff037c847c5ec8c185cd3bdb7abad0a02d4';

  const [title, setTitle] = useState(event.title);
  const [subtitle, setSubtitle] = useState(event.subtitle);
  const [futurebotRequired, setFuturebotRequired] = useState(
    (event.nft_required ?? []).includes(futurebot_policy_id)
  );
  const [deluxebotRequired, setDeluxebotRequired] = useState(
    (event.nft_required ?? []).includes(deluxebot_policy_id)
  );

  const [twitchChannelName, setTwitchChannelName] = useState(event.twitch_channel_name);
  const [streamURL, setStreamURL] = useState(event.stream_url);
  // const [startTime, setStartTime] = useState(event.start_time);
  // const [endTime, setEndTime] = useState(event.end_time);
  // const [displayStartTime, setDisplayStartTime] = useState(event.display_start_time);
  const [backgroundUrl, setBackgroundUrl] = useState(event.background_url);
  const [furioosId, setFurioosId] = useState(event.furioos_id);
  const [windowsInstallerUrl, setWindowsInstallerUrl] = useState(event.windows_installer_url);
  const [appleSiliconInstallerUrl, setAppleSiliconInstallerUrl] = useState(
    event.apple_silicon_installer_url
  );
  const [hidden, setHidden] = useState(event.hidden);

  const handleImage = async (e) => {
    const response = await API.adminGetEventBackgroundPresignedUrl(accessToken, event.id);
    if (response.status !== 200) {
      window.alert('Error getting presignedURL');
      return;
    }
    const data = await response.json();
    let selectedFile = e.target.files[0];
    const s3Response = await fetch(data.presignedUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': 'image/png'
      },
      body: selectedFile
    });
    if (s3Response.status !== 200) {
      window.alert('Failed to upload the image');
      return;
    } else {
      setBackgroundUrl(s3Response.url.split('?')[0]);
    }
  };

  async function updateEvent() {
    if (accessToken) {
      let nft_required = [];
      if (futurebotRequired) {
        nft_required.push(futurebot_policy_id);
      }
      if (deluxebotRequired) {
        nft_required.push(deluxebot_policy_id);
      }

      const response = await API.adminUpdateEvent(accessToken, {
        id: event.id,
        title: title,
        subtitle: subtitle,
        twitch_channel_name: twitchChannelName,
        stream_url: streamURL,
        background_url: backgroundUrl,
        nft_required: nft_required,
        furioos_id: furioosId,
        windows_installer_url: windowsInstallerUrl,
        apple_silicon_installer_url: appleSiliconInstallerUrl,
        hidden: hidden
      });
      if (response.status !== 204) {
        window.alert('Error updating event', event.id);
        return;
      }

      window.alert('Event updated successfully!');
    }
  }

  return (
    <div className="info-container">
      <div className="input-container">
        <label htmlFor={`inputId-${event.id}`}>ID:</label>
        <input
          class="event-input"
          id={`inputId-${event.id}`}
          type="text"
          value={event.id}
          disabled={true}
        />
      </div>
      <div className="input-container">
        <label htmlFor={`inputTitle-${event.id}`}>Title:</label>
        <input
          class="event-input"
          id={`inputTitle-${event.id}`}
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>
      <div className="input-container">
        <label htmlFor={`inputSubtitle-${event.id}`}>Subtitle:</label>
        <input
          class="event-input"
          id={`inputSubtitle-${event.id}`}
          type="text"
          value={subtitle}
          onChange={(e) => setSubtitle(e.target.value)}
        />
      </div>

      {/* <div className="input-container"><label htmlFor={`inputStartTime-${event.id}`}>Start Time:</label><input id={`inputStartTime-${event.id}`} type="datetime-local" value={startTime} onChange={(e) => setStartTime(e.target.value)} /></div>
            <div className="input-container"><label htmlFor={`inputEndTime-${event.id}`}>End Time:</label><input id={`inputEndTime-${event.id}`} type="datetime-local" value={endTime} onChange={(e) => setEndTime(e.target.value)} /></div>
            <div className="input-container"><label htmlFor={`inputDisplayStartTime-${event.id}`}>Display Start Time:</label><input id={`inputDisplayStartTime-${event.id}`} type="datetime-local" value={displayStartTime} onChange={(e) => setDisplayStartTime(e.target.value)} /></div>
            <div className="input-container"><label htmlFor={`inputBackgroundUrl-${event.id}`}>Background URL:</label><input id={`inputBackgroundUrl-${event.id}`} type="text" value={backgroundUrl} onChange={(e) => setBackgroundUrl(e.target.value)} /></div> */}
      <div className="input-container">
        <label htmlFor={`inputTwitchChannelName-${event.id}`}>Twitch:</label>
        <input
          class="event-input"
          id={`inputTwitchChannelName-${event.id}`}
          type="text"
          value={twitchChannelName}
          onChange={(e) => setTwitchChannelName(e.target.value)}
        />
      </div>
      <div className="input-container">
        <label htmlFor={`inputStreamURL-${event.id}`}>IVS:</label>
        <input
          class="event-input"
          id={`inputStreamURL-${event.id}`}
          type="text"
          value={streamURL}
          onChange={(e) => setStreamURL(e.target.value)}
        />
      </div>
      <div className="input-container">
        <label htmlFor={`inputFurioosId-${event.id}`}>Vagon:</label>
        <input
          class="event-input"
          id={`inputFurioosId-${event.id}`}
          type="text"
          value={furioosId}
          onChange={(e) => setFurioosId(e.target.value)}
        />
      </div>
      <div>
        <b>Desktop Clients</b>
      </div>
      <div className="input-container">
        <label htmlFor={`inputWindowsInstallerUrl-${event.id}`}>Windows:</label>
        <input
          class="event-input"
          id={`inputWindowsInstallerUrl-${event.id}`}
          type="text"
          value={windowsInstallerUrl}
          onChange={(e) => setWindowsInstallerUrl(e.target.value)}
        />
      </div>
      <div className="input-container">
        <label htmlFor={`inputAppleSiliconInstallerUrl-${event.id}`}>Mac:</label>
        <input
          class="event-input"
          id={`inputAppleSiliconInstallerUrl-${event.id}`}
          type="text"
          value={appleSiliconInstallerUrl}
          onChange={(e) => setAppleSiliconInstallerUrl(e.target.value)}
        />
      </div>
      <div className="input-container">
        <label htmlFor={`inputBackgroundUrl-${event.id}`}>Upload Image :</label>
        <input
          class="event-input"
          id={`inputBackgroundUrl-${event.id}`}
          type="file"
          accept="image/*"
          onChange={handleImage}
        />
      </div>
      <div className="input-container">
        <small>Recommended size: 1370x400</small>
      </div>
      {/* Disable NFT Requirement until necessary again */}
      <div className="input-container" style={{ display: 'none' }}>
        <label htmlFor={`inputFuturebotRequired-${event.id}`}>Futurebots are tickets:</label>
        <input
          class="event-input"
          id={`inputFuturebotRequired-${event.id}`}
          type="checkbox"
          checked={futurebotRequired}
          onChange={(e) => setFuturebotRequired(e.target.checked)}
        />
      </div>
      {/* Disable NFT Requirement until necessary again */}
      <div className="input-container" style={{ display: 'none' }}>
        <label htmlFor={`inputDeluxebotRequired-${event.id}`}>Deluxebots are tickets:</label>
        <input
          class="event-input"
          id={`inputDeluxebotRequired-${event.id}`}
          type="checkbox"
          checked={deluxebotRequired}
          onChange={(e) => setDeluxebotRequired(e.target.checked)}
        />
      </div>
      <div className="input-container">
        <label htmlFor={`inputHidden-${event.id}`}>Private:</label>
        <input
          class="event-input"
          id={`inputHidden-${event.id}`}
          type="checkbox"
          checked={hidden}
          onChange={(e) => setHidden(e.target.checked)}
        />
      </div>
      <div className="actions-container">
        <button className="ff-button" onClick={updateEvent}>
          Update
        </button>
        <Link className="ff-button" to={`/events/${event.id}`}>
          Enter Event
        </Link>
      </div>
    </div>
  );
};

export default EditEvent;
