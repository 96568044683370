import React, { useEffect } from 'react';
import './ConnectWallet.css';
import API from '../API';
import { useAuth0 } from '@auth0/auth0-react';
import NamiIcon from './images/nami_icon.png';
import EternlIcon from './images/eternl_icon.png';
import Mixpanel from './Mixpanel';
import Anvil from './Anvil';

const ConnectWalletAnvil = ({ callback, setWallets }) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    Mixpanel.track('Page Viewed', {
      'Page Type': 'Connect Wallet'
    });
  }, []);

  const signWallet = async (wallet_name) => {
    if (!isAuthenticated) {
      console.error('Not authenticated, cannot add wallet.');
    }

    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: 'ffclient:play'
    });
    const response = await API.getAnvilAuthPayload(accessToken);
    if (response.status !== 200) {
      alert('Could not get auth payload');
      return;
    }

    const data = await response.json();
    console.log(data.auth_payload);

    try {
      await Anvil.wallet.connect(wallet_name);
      const signDataPayload = await Anvil.wallet.signData(data.auth_payload, true);

      // Get Bech32 change address.
      const changeAddress = await Anvil.wallet.getBech32ChangeAddress();

      // Get network id.
      const networkId = await Anvil.wallet.getNetworkId();

      const signDataResponse = await API.addCardanoWalletAnvil(accessToken, {
        signature: signDataPayload.signature,
        key: signDataPayload.key,
        wallet: wallet_name,
        network_id: networkId,
        change_address: changeAddress
      });
      if (signDataResponse.status !== 200) {
        alert('Could not get signData Response');
        return;
      }
      const signatureData = await signDataResponse.json();
      console.log(signatureData);
      setWallets(signatureData.wallets);
    } catch (error) {
      console.log('error', error);
    }

    callback();
  };

  return (
    <div>
      <h2 className="connect-wallet-title">Connect your wallet</h2>
      <h3 className="connect-wallet-subtitle">
        Connect with one of our available wallet providers or create a new one.
      </h3>
      <ul className="wallet-name">
        <li>
          <button className="wallet-add-button" onClick={() => signWallet('nami')}>
            <img src={NamiIcon} alt="Nami wallet icon" />
            Nami
          </button>
        </li>
        <li>
          <button className="wallet-add-button" onClick={() => signWallet('eternl')}>
            <img src={EternlIcon} alt="Eternl wallet icon" />
            Eternl
          </button>
        </li>
      </ul>
      <h5 className="connect-wallet-footnote">
        New to Cardano?
        <br />
        <a href="https://youtu.be/dpfjKtLrucg" target="_blank" rel="noopener noreferrer">
          Learn more about wallets
        </a>
      </h5>
    </div>
  );
};

export default ConnectWalletAnvil;
