import React, { useState } from 'react';
import './AppHeader.css';
import HeaderLogo from './images/ff-header-logo.png';
import CartIcon from './images/cart.png';
import LoginLogoutButton from './LoginLogoutButton';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import Cart from './Cart';
import { CartState } from './CartContext';
const AppHeader = ({ children, wallets, setWallets, shopEnabled }) => {
  const [showCartModal, setCartModal] = useState(false);
  const { cartItems } = CartState();

  return (
    <header className="App-header">
      <div className="mobile-blocker">Sorry, mobile is not supported at this time.</div>
      <Link to="/">
        <img className="ff-logo" src={HeaderLogo} alt="Future Fest Logo" />
      </Link>
      {children}

      <div className="header-right-content">
        {shopEnabled && (
          <div className="cart">
            <div className="cart-logo">
              <img src={CartIcon} alt="Cart Logo" onClick={() => setCartModal(true)} />
            </div>
            <div className="cart-quantity">
              <div className="quantity">{cartItems.length}</div>
            </div>
          </div>
        )}
        <LoginLogoutButton wallets={wallets} setWallets={setWallets} />
      </div>

      <Modal
        isOpen={showCartModal}
        parentSelector={() => document.querySelector('.App')}
        className="CartModal__Content ff-modal-content"
        overlayClassName="CartModal__Overlay ff-overlay"
        onRequestClose={() => setCartModal(false)}
        shouldCloseOnOverlayClick={true}
      >
        <Cart />
      </Modal>
    </header>
  );
};

export default AppHeader;
