import React, { useState, useEffect } from 'react';
import './Storefront.css';

const EditProduct = ({
  selectedCollection,
  selectedProduct,
  editProductInfo,
  handleProductImageUpload
}) => {
  const [productName, setProductName] = useState('');
  const [productId, setProductId] = useState('');
  const [productPrice, setProductPrice] = useState('');
  const [productCategory, setProductCategory] = useState('');
  const [productImageUrl, setProductImageUrl] = useState('');

  useEffect(() => {
    setProductName(selectedProduct.name);
    setProductId(selectedProduct.id);
    setProductPrice(selectedProduct.price);
    setProductCategory(selectedProduct.category);
    setProductImageUrl(selectedProduct.image_url);
  }, [selectedProduct, selectedCollection]);

  const handleUpload = async (e) => {
    const presignedUrl = await handleProductImageUpload(e);
    if (presignedUrl) {
      setProductImageUrl(presignedUrl);
    } else {
      return window.alert('Failed to upload the image');
    }
  };

  return (
    <div className="info-container">
      <h3>Edit Product</h3>
      <div className="input-container">
        <label htmlFor="productName">Name:</label>
        <input
          id="productName"
          type="text"
          value={productName}
          onChange={(e) => setProductName(e.target.value)}
        ></input>
      </div>

      <div className="input-container">
        <label htmlFor="productId">ID:</label>
        <input
          id="productId"
          type="text"
          value={productId}
          onChange={(e) => setProductId(e.target.value)}
        ></input>
      </div>
      <div className="input-container">
        <label htmlFor="productCategory">Category:</label>
        <input
          id="productCategory"
          type="text"
          value={productCategory}
          onChange={(e) => setProductCategory(e.target.value)}
        ></input>
      </div>
      <div className="input-container">
        <label htmlFor="productPrice">Price:</label>
        <input
          id="productPrice"
          type="text"
          value={productPrice}
          onChange={(e) => setProductPrice(Number(e.target.value))}
        ></input>
      </div>
      <div className="input-container">
        <label htmlFor={'productImage'}>Upload Product Image:</label>
        <input id={`productImage`} type="file" accept="image/*" onChange={handleUpload} />
      </div>
      <div className="actions-container">
        <button
          className="ff-button"
          onClick={() =>
            editProductInfo(
              productId,
              selectedProduct.item_id,
              productName,
              productCategory,
              productPrice,
              productImageUrl
            )
          }
        >
          Update
        </button>
      </div>
    </div>
  );
};

export default EditProduct;
